var problemLayerNames = [];

export function displayWarningReportContainsOmissions(problemLayerName) {
    if (problemLayerName) {
        if (problemLayerNames.indexOf(problemLayerName) === -1) {
            problemLayerNames.push(problemLayerName);
        }
    }

    if ($("#warning").html() == "" || $("#warningProblemLayers").length > 0 || problemLayerNames.length > 0) {
        $('#warning').html('<strong>Warning: The report may contain omissions.</strong> <br>An unexpected response from one or more information sources occurred.'
            + (problemLayerNames.length > 0 ? (' <br>Layers affected: <span id="warningProblemLayers">' + problemLayerNames.join(', ') + '</span>') : ''));
        $('#warning').show();
    }
}

export function removeLayerFromWarningReportContainsOmissions(problemLayerName) {
    if (problemLayerName) {
        if (problemLayerNames.indexOf(problemLayerName) > -1) {
            problemLayerNames.splice(problemLayerNames.indexOf(problemLayerName), 1);
            if ($("#warning").html() !== "") {
                if (problemLayerNames.length === 0) {
                    $('#warning').hide();
                    $("#warning").html("");
                } else {
                    displayWarningReportContainsOmissions();
                }
            }
        }
    }
}

export function getCookie(name) {
    let nameEQ = name + "=";
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) == ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export function getHTMLCleanString(dirtyString) {
    var cleanString = dirtyString.replace(/([^a-z0-9]+)/gi, "");
    return cleanString;
}

export function getSectionTemplate(id) {
    var htmlString =
        "<div class='panel panel-default' id='" + id + "'>" +
        "<div class='panel-heading'>" +
        "<div class='panel-title' id='" + id + "-title'></div> " +
        "<div class='panel-desc' id='" + id + "-desc'></div>" +
        "</div>" +
        "<div class='panel-body' id='" + id + "-body'></div>" +

        "<div class='panel-maps' id='" + id + "-maps'></div>" +
        "</div>";

    return htmlString;
}

export function getTableTemplate(id, className) {
    className = className || "table table-condensed";
    var htmlString = "<table class='" + className + "' id='" + id + "'>" +
        "   <thead id='" + id + "-head'>" +
        "</thead>" +
        "<tbody id='" + id + "-body'>" +
        "</tbody>" +
        "</table>";
    return htmlString;
}

export function getListTemplate(id) {

    var htmlString = "<ul class='list-group' id='" + id + "'>" +
        "</ul>";
    return htmlString;
}

export function getDivTemplate(id, className) {

    var htmlString = "<div id='" + id + "' class='" + className + "' >" +
        "</div>";
    return htmlString;
}


export function getFormTemplate(id, reportName) {
    var htmlString =
        '<div class="row centered-form"  id="' + id + '">' +
        '<div class="col-lg-6">' +
        '<div class="panel panel-default">' +
        '<div class="panel-heading">' +
        '<div class="panel-title" id="' + id + '-head"></div>' +
        '</div>' +
        '<div class="panel-body">' +
        '<form role="form" id="' + id + '-body" >' +
        '<input type="hidden" id="report" name="report" value="' + reportName + '"/> ' +
        '</form>' +

        '</div>' +
        '</div>' +
        '</div>' +
        '</div>';
    return htmlString;
}

export function getFormItemTemplate(id, itemTitle, itemName, itemPlaceholder, itemGeoLocationEnabled, itemValue) {

    if (!itemValue) {
        itemValue = "";
    }
    var htmlString =
        '<div class="input-group input-group-sm" role="row" id="' + id + '">' +
        '<span class="input-group-addon">' + itemTitle + '</span>'

        if (itemGeoLocationEnabled) {
            htmlString = htmlString +
                '<span class="input-group-btn" >' +
                '<button autocomplete="off" id="' + id + '-btnLocation" class="btn btn-primary twitter-typeahead"  type="button" title="Near Current Location (mobile devices only)">' +
                '<i class="bi bi-geo-alt-fill"></i>' +
                '</button>' +
                '</span>';
        }
    
        htmlString = htmlString +
            '<input type="text" autocomplete="off" name="' + itemName + '" id="' + itemName + '" class="form-control twitter-typeahead" placeholder="' + itemPlaceholder + '" value="' + itemValue + '">' +
            '<span class="input-group-addon" id="' + id + '-groupAddon" />' +
            '<span class="input-group-btn" id="' + id + '-groupBtn" />' +
    
            '</div>';
    
    return htmlString;
}
