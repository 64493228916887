// This file is for the Flood Info webcomponent
// It is used in the report to display the flood info section
import { esri } from '../sccesri.js';
import { 
    getHTMLCleanString,
    getSectionTemplate,
    getTableTemplate
 } from '../report-shared.js';
 import { ReportMap } from './ReportMapElement.js';

export class FloodInfo extends HTMLElement {

    constructor() {
        super();
    }

    connectedCallback() {
        //this.innerHTML = "<span>placeholder</span>";
    }

    setup(siteGeoJSON, reportConfigSection, parentSectionName) {

        const thisFloodInfo = this;

        if (siteGeoJSON.features?.length !== 1) {
            console.log('Expecting a single site feature. Flood info unlikely to display');
        }
        const siteFeature = siteGeoJSON.features[0];

        for (let eleIdx = 0; eleIdx < reportConfigSection.elements.length; eleIdx++) {
            const configEle = reportConfigSection.elements[eleIdx];
            switch (configEle.type) {
                case "table": {
                    if (configEle.url) {
                        if (!configEle.url.endsWith('/')) {
                            configEle.url += '/';
                        }
                        let whereClause = configEle.filter;
                        for (const propKey in siteFeature.properties) {
                            whereClause = whereClause.replace('{' + propKey + '}', siteFeature.properties[propKey]);
                        }
                        const options = {
                            "where": whereClause,
                            "outFields": "*",
                            "returnCountOnly": false,
                            "f": "pjson"
                        }
                        const queryFunction = esri.MapService.query(configEle.url + configEle.layerId, options, configEle.layerName);
                        queryFunction.fail(function () {
                            thisFloodInfo.showTable(configEle, null, parentSectionName, eleIdx, "Could not load data");
                        });
                        queryFunction.done(function (data) {
                            if (data.features?.length > 0) {
                                const feature = data.features[0];
                                thisFloodInfo.showTable(configEle, feature, parentSectionName, eleIdx);
                            } else {
                                thisFloodInfo.showTable(configEle, null, parentSectionName, eleIdx);
                            }
                        });
                    }
                    break;
                }
                case "map": {
                    this.showMap(siteGeoJSON, parentSectionName, configEle, eleIdx);
                    break;
                }
            }
        }
    }

    showTable(configEle, feature, parentSectionName, eleIdx, errorMsg) {
        //Create the section using a template to add the map into.
        let sectionElementName = parentSectionName + "-element" + eleIdx;
        let sectionElementHTML = getSectionTemplate(sectionElementName);  //Get the default section template HTML snippet.
        $(sectionElementHTML).appendTo("#" + parentSectionName); //Add the HTML snippet to the DOM.
        $("#" + sectionElementName + "-title").html(configEle.title); //Add the title to the section
        $("#" + sectionElementName + "-desc").html(configEle.description); //Add the description to the section

        if (errorMsg) {
            $("#" + sectionElementName).append("<div class=\"alert alert-danger\" style=\"border-color: transparent\">" + errorMsg + "</div>");
            return;
        } else if (feature && configEle.tableReplacementFields?.length > 0) {
            for (const tableReplacementField of configEle.tableReplacementFields) {
                // if a value exists in one of these fields, then display it instead of the table
                if (feature.attributes[tableReplacementField]?.length > 0) {
                    $("#" + sectionElementName + "-desc").text(feature.attributes[tableReplacementField]);
                    return;
                }
            }
        } else if (!feature || configEle.displayFields.length == 0 || !feature.attributes[configEle.displayFields[0].field]) {
            $("#" + sectionElementName + "-desc").text("No information available. To receive a minimum floor level, please apply for a Flood Information Search.");
            return;
        }
        
        const tableElementName = sectionElementName + "-table";
        const tableElementHTML = getTableTemplate(tableElementName);
        $(tableElementHTML).appendTo("#" + sectionElementName);

        for (const displayField of configEle.displayFields) {
            const fieldValue = feature.attributes[displayField.field];

            if (fieldValue?.length > 0) { // If no value, don't show row
                const displayFieldDividerElementName = tableElementName + "-" + getHTMLCleanString(displayField.alias);
                $("#" + tableElementName + "-body").append("<div id='" + displayFieldDividerElementName + "'></div>");
    
                $("#" + tableElementName + "-body").append("<tr><td>" + displayField.alias + "</td><td>" + fieldValue + "</td></tr>");
            }
        }
    }

    showMap(siteGeoJSON, parentSectionName, mapConfig, eleIdx) {
        //Create the section using a template to add the map into.
        let sectionElementName = parentSectionName + "-element" + eleIdx;
        let sectionElementHTML = getSectionTemplate(sectionElementName);  //Get the default section template HTML snippet.
        $(sectionElementHTML).appendTo("#" + parentSectionName); //Add the HTML snippet to the DOM.
        $("#" + sectionElementName + "-title").html(mapConfig.title); //Add the title to the section
        $("#" + sectionElementName + "-desc").html(mapConfig.description); //Add the description to the section
    
        const reportMap = new ReportMap();
        $(reportMap).appendTo("#" + sectionElementName + "-maps");
        let mapElementName = sectionElementName + "-element-Map" + eleIdx;
        reportMap.setup(siteGeoJSON, mapElementName, mapConfig, false);
    }

    getHTMLCleanString(dirtyString) {
        let cleanString = dirtyString.replace(/([^a-z0-9]+)/gi, "");
        return cleanString;
    }
}
customElements.define('flood-info', FloodInfo);