/* eslint-disable no-prototype-builtins */
/**
 * Created by paulgrambauer on 26/08/2014.
 * Requires JQuery to be loaded in parent HTML page.
 */
import { displayWarningReportContainsOmissions, removeLayerFromWarningReportContainsOmissions } from './report-shared.js';

export var ogc = {};

let errorMessages = window.errorMessages || [];

ogc.utils = {
        getEqualToFilter: function (name, value) {
            var filter = "<ogc:Filter><ogc:PropertyIsEqualTo><ogc:PropertyName>" + name + "</ogc:PropertyName><ogc:Literal>" + value + "</ogc:Literal></ogc:PropertyIsEqualTo></ogc:Filter>"
            return encodeURI(filter)
        }
}

ogc.WMS = {
        getFeature: function (url, options) {
            //options.jsonpCallback= 'getJson';
                options.outputFormat= 'text/javascript';
                options.srs='urn:x-ogc:def:crs:EPSG:4326';
                options.request = 'GetFeature';
                options.tiled = 'True';
                options.version = '2.0.0';
                options.format_options = 'callback:parseResponse';

            var getFeatureFunction = $.ajax({
                type: "POST",
                url: url ,
                data: options,
                dataType:"jsonp",
                jsonp: false,
                jsonpCallback: "parseResponse",
                success: function (data) {
                    return (data)
                },
                error: function () {
                    $("#reportProgress").html('<strong>Information:</strong> I\'m sorry, But it appears as though this report is not configured correctly.<br><strong>Please contact the site administrator to correct this report.</strong>');
                    $('#reportProgress').show();
                }

            });


            return getFeatureFunction

        }
    };            

ogc.WFS = {
        getFeature: function(url, options, layerName) { return executeGetFeature (url, options, layerName)}
    };

function executeGetFeature (url, options, layerName) {

    options.outputFormat= 'application/json';
    options.srs='urn:x-ogc:def:crs:EPSG:4326';
    options.request = 'GetFeature';
    //options.version = '1.0.0';

    $.each(options, function (key, value) {
        if (typeof value == 'string' && value != null && value != "") {
          options[key] = value.replace(/\n/g, "\\n");
        }
    })

    var getFeatureFunction = $.post(
        url ,
        options,
        function(resultData, status){
          if (status != 'success') {
            displayWarningReportContainsOmissions(layerName);
          } else {
            removeLayerFromWarningReportContainsOmissions(layerName);
          }
          if (resultData.hasOwnProperty('error')){
            displayWarningReportContainsOmissions(layerName);
              if (errorMessages.indexOf(resultData.error.message ) == -1 ) {
                  $('#warning').html($('#warning').html() + '<br><small hint=""' + this.url + '">Details: ' + resultData.error.message + '</small>');
                  errorMessages.push(resultData.error.message)
              }
          } else {
              return resultData
          }
      }, 'json').fail(function () {
        displayWarningReportContainsOmissions(layerName);
      });
              
    return getFeatureFunction

}