/* eslint-disable no-prototype-builtins */
/**
 * Created by paulgrambauer on 26/08/2014.
 * Requires JQuery to be loaded in parent HTML page.
 */
import * as olLoadingstrategy from 'ol/loadingstrategy';
import Polygon from 'ol/geom/Polygon';
import * as olTilegrid from 'ol/tilegrid';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Circle, Fill, Icon, Style, Stroke } from 'ol/style';
import { EsriJSON } from 'ol/format';
import { displayWarningReportContainsOmissions, removeLayerFromWarningReportContainsOmissions, getCookie } from './report-shared.js';

export var esri = {};
let errorMessages = window.errorMessages || [];

esri.GeometryService = {
  buffer: function (url, options) {
    return $.ajax({
      type: "GET",
      url: url + "/buffer",
      data: options,
      dataType: "jsonp",
      // error: function (req, message, errorThrown) {
      //    alert(message + " " + errorThrown);
      // },
      success: function (data) {
        return data;
      } //this.data}

    });
  }
};

esri.MapService = {
  query: function (url, options, layerName) { return executeQuery(url, options, layerName) }
};


esri.FeatureService = {
  layer: function (serviceUrl, layer, layerName) {

    const vectorSource = new VectorSource({
      format: new EsriJSON(),
      loader: function (extent, resolution, projection, success, failure) {
        let url = serviceUrl + layer + '/query/?f=json&' +
          'returnGeometry=true&spatialRel=esriSpatialRelIntersects&geometry=' +
          encodeURIComponent('{"xmin":' + extent[0] + ',"ymin":' +
            extent[1] + ',"xmax":' + extent[2] + ',"ymax":' + extent[3] +
            ',"spatialReference":{"wkid":3857}}') +
          '&geometryType=esriGeometryEnvelope&inSR=3857&outFields=*' +
          '&outSR=3857';
        //Load the features
        $.ajax({
          url: url, dataType: 'jsonp', success: function (response) {
            if (response.error) {
              alert(response.error.message + '\n' +
                response.error.details.join('\n'));
            } else {
              // dataProjection will be read from document
              let features = vectorSource.getFormat().readFeatures(response, {
                featureProjection: projection
              });
              if (features.length > 0) {
                vectorSource.addFeatures(features);
                success(features);
                removeLayerFromWarningReportContainsOmissions(layerName);
              }
            }
          }
        }).fail(function () {
          displayWarningReportContainsOmissions(layerName);
          failure();
        });

      },
      strategy: olLoadingstrategy.tile(olTilegrid.createXYZ({
        tileSize: 512
      }))
    });

    const vector = new VectorLayer({
      source: vectorSource
    });

    //Set the Style
    $.ajax({
      url: serviceUrl + layer + '?f=json', dataType: 'jsonp', success: function (response) {
        if (response.error) {
          alert(response.error.message + '\n' +
            response.error.details.join('\n'));
        } else {
          const styleCache = {};
          const rendererField = response.drawingInfo.renderer.field1;
          const renderer = response.drawingInfo.renderer;
          const esriStyleConverter = new olarcgis.styleConverter();
          switch (renderer.type) {
            case "uniqueValue":
              for (var styleIndex = 0; styleIndex < renderer.uniqueValueInfos.length; styleIndex++) {
                const uniqueValueInfo = renderer.uniqueValueInfos[styleIndex]
                switch (uniqueValueInfo.symbol.type) {
                  case "esriPMS": {
                    const styleItem = esriStyleConverter.convertEsriPMS(uniqueValueInfo.symbol);
                    styleCache[uniqueValueInfo.value] = styleItem;
                    break;
                  }
                  case "esriSFS": {
                    const styleItem = esriStyleConverter.convertEsriSFS(uniqueValueInfo.symbol);
                    styleCache[uniqueValueInfo.value] = styleItem;
                    break;
                  }
                  case "esriSLS": {
                    const styleItem = esriStyleConverter.convertEsriSLS(uniqueValueInfo.symbol);
                    styleCache[uniqueValueInfo.value] = styleItem;
                    break;
                  }
                  case "esriSMS": {
                    const styleItem = esriStyleConverter.convertEsriSMS(uniqueValueInfo.symbol);
                    styleCache[uniqueValueInfo.value] = styleItem;
                    break;
                  }
                }
              }
              break;
          }

          vector.setStyle(function (feature) {
            if (rendererField) {
              var classify = feature.get(rendererField);
              let classifiedStyle = styleCache[classify];
              if (!classifiedStyle) {
                console.log('Classified style not found for ' + classify);
              }
              return classifiedStyle;
            } else {
              return null;
            }
          });
          removeLayerFromWarningReportContainsOmissions(layerName);
        }
      }
    }).fail(function () {
      displayWarningReportContainsOmissions(layerName);
    });

    return vector;
  }
};

esri.getTokenServiceFunction = function (url) {
  //Obtain the token services URL
  var arcGISInfoUrl = url.split('/rest/services/')[0] + '/rest/info'
  var arcGISInfoOptions = { "f": "pjson" }
  var arcGISInfoFunction = $.ajax({
    type: "GET",
    url: arcGISInfoUrl,
    data: arcGISInfoOptions,
    dataType: "jsonp"

  });
  return arcGISInfoFunction

}

esri.getToken = function (event, url, username, password) {
  //Obtain the token services URL
  url = url ? url : $('input#login-url').val()
  username = username ? username : $('input#login-username').val()
  password = password ? password : $('input#login-password').val()
  var arcGISInfoUrl = url.split('/rest/services/')[0] + '/rest/info'
  var arcGISInfoOptions = { "f": "pjson" }
  var arcGISInfoFunction = $.ajax({
    type: "GET",
    url: arcGISInfoUrl,
    data: arcGISInfoOptions,
    dataType: "jsonp"

  });
  arcGISInfoFunction.done(function (resultData) {
    if (resultData.authInfo && resultData.authInfo.isTokenBasedSecurity == true) {
      var tokenServicesUrl = resultData.authInfo.tokenServicesUrl
      var tokenServicesOptions = {
        "username": username,
        "password": password,
        "f": "pjson"
      }
      //Obtain the token
      var arcGISTokenServicesFunction = $.ajax({
        type: "POST",
        url: tokenServicesUrl + '/generateToken',
        data: tokenServicesOptions,
        dataType: "jsonp"

      });

      arcGISTokenServicesFunction.done(function (resultData) {
        return resultData.token
      })
    }
  });


  //return token
}


function executeQuery(url, options, layerName) {

  $.each(options, function (key, value) {
    if (typeof value == 'string' && value != null && value != "") {
      options[key] = value.replace(/\n/g, "\\n");
    }
  });

  const cookieAccessToken = getCookie('gis_access_token');
  let queryParams = '';
  if (cookieAccessToken && url.indexOf('Secure') > -1) {
    queryParams = '?token=' + cookieAccessToken;
  }

  var mapQueryFunction = $.post(
    url + "/query" + queryParams,
    options,
    function (resultData, status) {
      if (status != 'success') {
        displayWarningReportContainsOmissions(layerName);
      } else {
        removeLayerFromWarningReportContainsOmissions(layerName);
      }
      if (resultData.hasOwnProperty('error')) {
        displayWarningReportContainsOmissions(layerName);
        if (errorMessages.indexOf(resultData.error.message) == -1) {
          $('#warning').html($('#warning').html() + '<br><small hint=""' + this.url + '">Details: ' + resultData.error.message + '</small>');
          errorMessages.push(resultData.error.message);
        }
      }
    }, 'json').fail(function () {
      displayWarningReportContainsOmissions(layerName);
    });

  return mapQueryFunction

}

// see http://resources.arcgis.com/en/help/rest/apiref/index.html?symbol.html

if (!window.olarcgis) {
  window.olarcgis = {};
}
export var olarcgis = window.olarcgis;

olarcgis.styleConverter = function (options) {
  for (var prop in options) {
    if (this.hasOwnProperty(prop)) {
      this[prop] = options[prop];
    }
  }
  this.converters = {};
  this.converters['esriPMS'] = this.convertEsriPMS;
  this.converters['esriSFS'] = this.convertEsriSFS;
  this.converters['esriSMS'] = this.convertEsriSMS;
  this.converters['esriSMS'] = this.convertEsriSLS;
  this.renderers = {};
  this.renderers['uniqueValue'] = this.renderUniqueValue;
  this.renderers['simple'] = this.renderSimple;
  this.renderers['classBreaks'] = this.renderClassBreaks;
};

olarcgis.styleConverter.prototype.convertPointToPixel = function (point) {
  return Math.ceil(point / 0.75);
};

// convert an Esri Picture Marker Symbol
olarcgis.styleConverter.prototype.convertEsriPMS = function (symbol) {
  var width = olarcgis.styleConverter.prototype.convertPointToPixel(symbol.width);
  var height = olarcgis.styleConverter.prototype.convertPointToPixel(symbol.height);
  var img = document.createElement('img');
  img.src = 'data:' + symbol.contentType + ';base64, ' + symbol.imageData;
  return new Style({
    image: new Icon({
      img: img,
      imgSize: [width, height], // reqd for OL v7 (remove once updated to v8)
      size: [width, height] // reqd for OL v8
    })
  });
};

olarcgis.styleConverter.prototype.transformColor = function (color) {
  // alpha channel is different, runs from 0-255 but in ol3 from 0-1
  return [color[0], color[1], color[2], color[3] / 255];
};

// convert an Esri Simple Fill Symbol
olarcgis.styleConverter.prototype.convertEsriSFS = function (symbol) {
  var fill = new Fill({ color: this.transformColor(symbol.color) });
  var stroke = new Stroke({ color: this.transformColor(symbol.outline.color) });
  return new Style({
    fill: fill,
    stroke: stroke
  });
};

// convert an Esri Simple Marker Symbol
olarcgis.styleConverter.prototype.convertEsriSMS = function (symbol, opt_size) {
  if (symbol.style === 'esriSMSCircle') {
    // TODO implement outline style (e.g. "esriSLSSolid")
    var circle = new Circle({
      radius: opt_size ? opt_size : symbol.size,
      fill: new Fill({ color: this.transformColor(symbol.color) }),
      stroke: new Stroke({ color: this.transformColor(symbol.outline.color), width: symbol.outline.width })
    });
    return new Style({
      image: circle
    });
  }
};

// convert an Esri Simple Line Symbol
olarcgis.styleConverter.prototype.convertEsriSLS = function (symbol) {
  if (symbol.style === 'esriSLSSolid') {
    // TODO implement outline style (e.g. "esriSLSSolid")
    let line = new Stroke({
      color: this.transformColor(symbol.color),
      width: symbol.width
    });
    return new Style({
      stroke: line
    });
  } else if (symbol.style === 'esriSLSDash') {
    let line = new Stroke({
      color: this.transformColor(symbol.color),
      width: symbol.width,
      lineDash: [10, 5]
    });
    return new Style({
      stroke: line
    });
  } else if (symbol.style === 'esriSLSDashDotDot') {
    let line = new Stroke({
      color: this.transformColor(symbol.color),
      width: symbol.width,
      lineDash: [6, 2, 2]
    });
    return new Style({
      stroke: line
    });
  } else
    alert('Still need to consider the featureservice style:' + symbol.style)
};

olarcgis.styleConverter.prototype.renderSimple = function (renderer) {
  return this.converters[renderer.symbol.type].call(this, renderer.symbol);
};

olarcgis.styleConverter.prototype.renderClassBreaks = function (renderer) {
  var field = renderer.field;
  var minDataValue = renderer.visualVariables[0].minDataValue;
  var maxDataValue = renderer.visualVariables[0].maxDataValue;
  var minSize = renderer.visualVariables[0].minSize;
  var maxSize = renderer.visualVariables[0].maxSize;
  var sizes = [];
  var size = minSize;
  var symbol = renderer.classBreakInfos[0].symbol;
  while (size <= maxSize) {
    sizes.push(olarcgis.styleConverter.prototype.convertPointToPixel(size));
    size += minSize;
  }
  var classes = [];
  var min = minDataValue;
  var geomFunction = function (feature) {
    var geometry = feature.getGeometry();
    if (geometry && geometry instanceof Polygon) {
      return geometry.getInteriorPoint();
    }
  };
  var increment = (maxDataValue - minDataValue) / sizes.length;
  for (var i = 0, ii = sizes.length; i < ii; ++i) {
    var style = this.converters[symbol.type].call(this, symbol, sizes[i]);
    style.setGeometry(geomFunction);
    classes.push({ min: min, max: min + increment, style: style });
    min += increment;
  }
  return (function () {
    return function (feature) {
      var value = feature.get(field);
      for (var i = 0, ii = classes.length; i < ii; ++i) {
        if (value >= classes[i].min && value <= classes[i].max) {
          return [classes[i].style];
        }
      }
    };
  }());
};

olarcgis.styleConverter.prototype.renderUniqueValue = function (renderer) {
  var field = renderer.field1;
  var infos = renderer.uniqueValueInfos;
  var me = this;
  return (function () {
    var hash = {};
    for (var i = 0, ii = infos.length; i < ii; ++i) {
      var info = infos[i], symbol = info.symbol;
      hash[info.value] = [me.converters[symbol.type].call(me, symbol)];
    }
    return function (feature) {
      return hash[feature.get(field)];
    };
  }());
};

olarcgis.styleConverter.prototype.read = function (drawingInfo) {
  return this.renderers[drawingInfo.renderer.type].call(this, drawingInfo.renderer);
};