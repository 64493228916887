/* eslint-disable no-prototype-builtins */
/**
 * Created by paulgrambauer on 3/09/2014.
 */

/*The purpose is to manage the report generation data flow, the steps are
1. Identify the site and return a GeoJSON object
concurrently
    2a. Populate the site elements with the GeoJSON content
    2b. Drill down the report elements with the GeoJSON geometry.
        3a. For each report element, update the DOM in order.

   */

import 'ol/ol.css';
import { WKT, GeoJSON } from 'ol/format';
import { 
    getCookie,
    getDivTemplate,
    getFormTemplate,
    getFormItemTemplate,
    getHTMLCleanString,
    getListTemplate,
    getSectionTemplate,
    getTableTemplate
 } from './report-shared.js';
import { ogc } from './sccogc.js';
import { esri } from './sccesri.js';
import { ReportMap } from './components/ReportMapElement.js';
import { FloodInfo } from './components/FloodInfoElement.js';
import { arcgisToGeoJSON, geojsonToArcGIS } from '@terraformer/arcgis';
import buffer from '@turf/buffer';
import centroid from '@turf/centroid';
import distance from '@turf/distance';
import simplify from '@turf/simplify';
import union from '@turf/union';

// jQuery + typeahead is not imported but is expected to have been loaded
let $ = window.$;

var report = null;
var appVersion = "4.2.0";

var insertedDisplayGroups = [];
var insertedLayerValues = {};

const loadReportConfiguration = () => {
    //Get the report name - which is the same as the javascript configuration file
    var reportName = getUrlParam("report") || "default";

    if (reportName === "da") { // a google result can have this
        reportName = "da_public";
    }

    $.getJSON("reports/" + reportName + ".json?=" + Date.now(),
        function (data) {
            //Successfully found the script file, now eval the reportJSON file contents to load into the DOM as a report object
            report = data;

            // Does report require a logged in user
            if (report.arcGisLoginRequired) {
                const cookieAccessToken = getCookie('gis_access_token');
                if (!cookieAccessToken) {
                    const redirectUrl = encodeURIComponent(location.pathname + window.location.search);
                    window.location.href = '/login.html?redirectUrl=' + redirectUrl;
                }
            }

            var reportStyle = getUrlParam("style");
            switch (reportStyle) {
                case 'report':
                    //remove the Bannering elemeents
                    $('#logo').hide();
                    $('#message').hide();
                    $('#navigation').hide();
                    break;
                default:
                    $('#message').show();
                    $('#navigation').show();
                    break;
            }


            //Identify the site location

            if (report.siteInfo.siteFilters.length > 0) {
                identifySiteLocation(function (siteGeoJSON) {

                    $('#reportProgress').html('<strong>Information:</strong> Check that report has completed loading before printing.');
                    $('#reportProgress').show();

                    //update the report layout details.
                    updateReportDetails();

                    showSiteInformation(siteGeoJSON, report, 'siteInfo');
                    showDrillDownInformation(siteGeoJSON, report, 'drillDownInfo');
                    if (report['floodInfo']) {
                        showFloodInformation(siteGeoJSON, report['floodInfo'], 'floodInfo', 0);
                    }

                    $('#reportDisclaimer').show();


                });
            } else {
                //update the report layout details.
                updateReportDetails();
            }
        });
}

window.onload = loadReportConfiguration();

/**
 * Updates the report content details using the configurations in the reportConfig object.
 * @param reportConfig - created from evaluating the report configuration file.  Defaults to report if not specified.
 */
function updateReportDetails(reportConfig) {
    reportConfig = reportConfig || report;
    if (reportConfig.information && reportConfig.information.length > 0) {
        $("#reportProgress").html('<strong>Information:</strong> ' + reportConfig.information);
        $('#reportProgress').show();
    }

    $("#reportTitle").html(reportConfig.title + '<sup><small>©</small></sup>');
    window.document.title = reportConfig.title;
    $("#siteInformationTitle").html(reportConfig.siteInformationTitle);
    $("#reportDescription").html(reportConfig.description);
    $("#reportVersion").html('<small>Info: Report-' + reportConfig.version + ', App-' + appVersion + "</small>");

    if (reportConfig.navBarBackgroundColour && reportConfig.navBarBackgroundColour.length > 0) {
        $("#nav").css("background-color", reportConfig.navBarBackgroundColour);
    }
}

/**
 * Appends the default layer values to the drill down layer object if null or non existing.
 * @param dsourceObject - the drill down Element  object from the report config file.
 * @param targetObject - the drill down layer object from the report config file.
 */
function populateLayerDefaults(drillDownInfoElement, drillDownLayer) {
    //iterate through each of the default keys, and update the drilldownlayer with the keys if they don't exist.
    var layerObject = {};

    drillDownLayer = mergeRecursiveObject(layerObject, drillDownLayer); //make a new object of the drill down layer
    drillDownLayer = mergeRecursiveObject(drillDownLayer, drillDownInfoElement.layerDefaults);

    if (drillDownLayer.geometryIntersectQueryLayers) {
        for (const oll of drillDownLayer.geometryIntersectQueryLayers) {
            mergeRecursiveObject(oll, drillDownInfoElement.layerDefaults);
        }
    }
    if (drillDownLayer.map?.overlayLayer && !drillDownLayer.map.overlayLayer.hasOwnProperty('layerId')) {
        delete drillDownLayer.map.overlayLayer;
    }
    if (drillDownLayer.map?.overlayLayers && drillDownInfoElement.layerDefaults.map?.overlayLayer) {
        for (const oll of drillDownLayer.map.overlayLayers) {
            mergeRecursiveObject(oll, drillDownInfoElement.layerDefaults.map.overlayLayer);
        }
    }

    return drillDownLayer;
}

function getESRIGeometryType(geoJSONGeometry) {


    var geometryType = "esriGeometryEnvelope"
    switch (geoJSONGeometry.type) {
        case "Polygon":
            geometryType = "esriGeometryPolygon";
            break;
        case "MultiPolygon":
            geometryType = "esriGeometryPolygon";
            break;
        case "Point":
            geometryType = "esriGeometryPoint";
            break;
        case "MultiPoint":
            geometryType = "esriGeometryMultipoint";
            break;
        case "LineString":
            geometryType = "esriGeometryPolyline";
            break;
        case "MultiLineString":
            geometryType = "esriGeometryPolyline";
            break;
        default:
            geometryType = "esriGeometryEnvelope";

    }
    return geometryType;
}

function flipGeoJSON(sourceJson) {
    var json = $.extend(true, {}, sourceJson);

    function findAllCoordinates(obj) {
        var objects = [];
        for (var i in obj) {
            if (i === "coordinates") {
                objects.push(obj[i]);
            } else if (typeof obj[i] == 'object') {
                objects = objects.concat(
                    findAllCoordinates(obj[i])
                );
            }
        }
        return objects;
    }

    function flipAllCoordinatesArray(coordinates) {
        if (coordinates && coordinates.length === 2 && typeof coordinates[0] === 'number') {
            coordinates.reverse();
        } else {
            for (var p = 0; p < coordinates.length; p++) {
                flipAllCoordinatesArray(coordinates[p]);
            }
        }
    }

    var values = findAllCoordinates(json);

    for (var i = 0; i < values.length; i++) {
        flipAllCoordinatesArray(values[i]);
    }

    return json;
}



/**
 * Performs a drill down analysis using the site Geometry, calling the callback function upon successful response.
 * @param siteGeoJSON - the result from applying the site filter using the identitySiteLocation function - in GeoJSON format.
 * @param drillDownLayer - the drill down layer element from the report configuration file.
 */
async function performGeometryDrillDown(siteGeoJSON, drillDownLayer, featureElementName, featureIndex, layerPlaceholderElementName, mapLayerPlaceholderElementName) {
    if (drillDownLayer.hasOwnProperty('performQuery') && (drillDownLayer.performQuery == false)) {
        var resultGeoJSON = {
            type: "FeatureCollection",
            'crs': {
                'type': 'name',
                'properties': {
                    'name': 'EPSG:4326'
                }
            },
            features: []
        }
        return resultGeoJSON;
    } else {
        var geoJSONGeometry = siteGeoJSON.features[featureIndex].geometry;
        var searchDistance = drillDownLayer.searchDistance;

        if (drillDownLayer.hasOwnProperty('display') && (drillDownLayer.display == 'marker')) {
            drillDownLayer.returnGeometry = true;
        }

        if (searchDistance) {
            //Buffer the geometry to extend to include the seach results
            //Set the drill down to return geometry - so that the search distance can be calculated on result.
            drillDownLayer.returnGeometry = true;
            var geoJSONGeometryBufferCalc = buffer(siteGeoJSON.features[featureIndex], searchDistance, { units: 'meters' });
            var geoJSONGeometryBuffer = {};
            if (geoJSONGeometryBufferCalc.type == 'Feature') {
                geoJSONGeometryBuffer.type = 'FeatureCollection';
                geoJSONGeometryBuffer.features = [geoJSONGeometryBufferCalc]
            } else {
                geoJSONGeometryBuffer = geoJSONGeometryBufferCalc;
            }

            let bufferUnion;
            for (var fi = 0; fi < geoJSONGeometryBuffer.features.length; fi++) {
                if (!bufferUnion) {
                    bufferUnion = $.extend({}, geoJSONGeometryBuffer.features[0]);
                } else {
                    bufferUnion = union(bufferUnion, geoJSONGeometryBuffer.features[fi]);
                }
            }

            var reducedPrecissionGeoJSONBuffer = JSON.stringify(bufferUnion, function (key, val) {
                if (val) {
                    return val.toFixed ? Number(val.toFixed(6)) : val;
                } else {
                    return val
                }
            })
            geoJSONGeometryBuffer = JSON.parse(reducedPrecissionGeoJSONBuffer);

            geoJSONGeometry = geoJSONGeometryBuffer.geometry
        }


        switch (drillDownLayer.service) {
            case "FeatureService": {

                const esriGeometry = geojsonToArcGIS(geoJSONGeometry);
                const geometryType = getESRIGeometryType(geoJSONGeometry);

                drillDownLayer.returnGeometry = drillDownLayer.returnGeometry || false;
                const options = {
                    returnGeometry: drillDownLayer.returnGeometry,
                    geometryType: geometryType,
                    "where": drillDownLayer.filter || "",
                    "orderByFields": drillDownLayer.orderByFields,
                    "relationParam": drillDownLayer.siteGeometryRelationPattern,
                    "spatialRel": drillDownLayer.siteGeometryFilter,
                    "geometry": JSON.stringify(esriGeometry),
                    "outFields": "*",
                    "outSR": 4326,
                    "inSR": 4326,
                    "f": "pgeojson"
                };
                const url = drillDownLayer.url + "/" + drillDownLayer.layerId;// + "/query";

                const data = await esri.FeatureService.query(url, options, drillDownLayer.layerName);
                $("#" + featureElementName + "-loader").hide();
                return returnDrillDownResult(data);

                break;
            }
            case "MapService": {

                const esriGeometry = geojsonToArcGIS(geoJSONGeometry);
                const geometryType = getESRIGeometryType(geoJSONGeometry);

                drillDownLayer.returnGeometry = drillDownLayer.returnGeometry || false;
                const options = {
                    returnGeometry: drillDownLayer.returnGeometry,
                    geometryType: geometryType,
                    "where": drillDownLayer.filter || "",
                    "orderByFields": drillDownLayer.orderByFields,
                    "relationParam": drillDownLayer.siteGeometryRelationPattern,
                    "spatialRel": drillDownLayer.siteGeometryFilter,
                    "geometry": JSON.stringify(esriGeometry),
                    "outFields": "*",
                    "outSR": 4326,
                    "inSR": 4326,
                    "f": "pjson"
                };
                const url = drillDownLayer.url + "/" + drillDownLayer.layerId;// + "/query";

                try
                {
                    const data = await esri.MapService.query(url, options, drillDownLayer.layerName);
                    if (data.hasOwnProperty('error')) {
                        appendTableRowWarningCouldNotLoadAfterElement(layerPlaceholderElementName, drillDownLayer.layerName);
                    } else {
                        $("#" + featureElementName + "-loader").hide();
                        return returnDrillDownResult(data);
                    }
                } catch (error) {
                    appendTableRowWarningCouldNotLoadAfterElement(layerPlaceholderElementName, drillDownLayer.layerName);
                }

                break;
            }
            case "WFS": {

                const wktformat = new WKT();
                const geojsonformat = new GeoJSON();
                const flippedGeometry = geojsonformat.readGeometry(flipGeoJSON(geoJSONGeometry));
                const wktGeometry = wktformat.writeGeometry(flippedGeometry);

                drillDownLayer.returnGeometry = drillDownLayer.returnGeometry || false;
                const geometryName = drillDownLayer.geometryName || "Shape";
                const options = {
                    "version": drillDownLayer.version || "2.0.0",
                    "request": drillDownLayer.request || "GetFeature",
                    "typenames": drillDownLayer.layerId,
                    "cql_filter": "INTERSECTS(" + geometryName + ", " + wktGeometry + ")"
                };
                if (drillDownLayer.filter) {
                    options.cql_filter = options.cql_filter + ' and ' + drillDownLayer.filter
                }

                const url = drillDownLayer.url;

                const data = await ogc.WFS.getFeature(url, options, drillDownLayer.layerName);
                $("#" + featureElementName + "-loader").hide();
                return returnDrillDownResult(data);
                break;
            }
            case "WMS": {

                const wktformat = new WKT();
                const geojsonformat = new GeoJSON();
                const geometry = geojsonformat.readGeometry(flipGeoJSON(geoJSONGeometry));
                const wktGeometry = wktformat.writeGeometry(geometry);

                drillDownLayer.returnGeometry = drillDownLayer.returnGeometry || false;
                const geometryName = drillDownLayer.geometryName || "Shape"
                const options = {
                    "tiled": "true",
                    "version": drillDownLayer.version || "2.0.0",
                    "request": drillDownLayer.request || "GetFeature",
                    "typenames": drillDownLayer.layerId,
                    "cql_filter": "INTERSECTS(" + geometryName + ", " + wktGeometry + ")"
                };
                if (drillDownLayer.filter) {
                    options.cql_filter = drillDownLayer.filter;
                }

                const url = drillDownLayer.url;

                const data = await ogc.WMS.getFeature(url, options);
                $("#" + featureElementName + "-loader").hide();
                return returnDrillDownResult(data)
                break;
            }
            default:
            //alert('There were no valid site filters defined in the report configuration file, please correct this.');
        }
    }

    function returnDrillDownResult(data) {
        //Construct the GeoJSON feature collection, as terraformer only converts a single ArcGIS feature to GeoJSON.
        var resultGeoJSON = {
            type: "FeatureCollection",
            'crs': {
                'type': 'name',
                'properties': {
                    'name': 'EPSG:4326'
                }
            },
            features: []
        }
        if (data) {
            if (data.type == "FeatureCollection") {
                resultGeoJSON = data;
            } else {
                if (data.hasOwnProperty("error")) {
                    if (drillDownLayer.displayWhen == 'no result' || drillDownLayer.displayWhen == 'always') {
                        // create a dummy feature with no properties so that displayWhen: always and no result works.
                        const feature = { properties: { null: null }, id: 0, bbox: null, geometry: null, type: 'Feature' }
                        resultGeoJSON.features.push(feature);
                    }
                }
                if (data.hasOwnProperty("features")) {

                    for (var resultFeatureIndex = 0; resultFeatureIndex < data.features.length; resultFeatureIndex++) {
                        const feature = arcgisToGeoJSON(data.features[resultFeatureIndex]);
                        feature.id = resultFeatureIndex;
                        resultGeoJSON.features.push(feature);
                    }
                }
            }
        }

        resultGeoJSON = appendDistanceProperties(resultGeoJSON, geoJSONGeometry);
        resultGeoJSON = appendLatLongProperties(resultGeoJSON);

        //Include the markerID values.
        for (var featureIndex = 0; featureIndex < resultGeoJSON.features.length; featureIndex++) {
            resultGeoJSON.features[featureIndex].properties.siteReportMarkerId = featureIndex + 1;
        }

        return resultGeoJSON;
    }
}

/**
 * Appends the lat long geometry centroid values from the 'GEOJSON' object
 * @param featuresGeoJSON
 * @param fromGeoJSON
 */
function appendLatLongProperties(geoJSON) {
    for (var featureIndex = 0; featureIndex < geoJSON.features.length; featureIndex++) {
        var featureGeoJSON = geoJSON.features[featureIndex]
        if (featureGeoJSON.geometry) {
            var fromCentroid = centroid(featureGeoJSON);
            featureGeoJSON.properties.lat = fromCentroid.geometry.coordinates[1]
            featureGeoJSON.properties.lon = fromCentroid.geometry.coordinates[0]
        }
    }

    return geoJSON
}

/**
 * Appends the distance values from the 'FromGEOJSON' to each of the feature in the featuresGeoJSON object
 * @param featuresGeoJSON
 * @param fromGeoJSON
 */
function appendDistanceProperties(featuresGeoJSON, fromGeoJSON) {

    for (var featureIndex = 0; featureIndex < featuresGeoJSON.features.length; featureIndex++) {
        var featureGeoJSON = featuresGeoJSON.features[featureIndex]
        if (featureGeoJSON.geometry) {
            if (!featureGeoJSON.properties.hasOwnProperty('siteReportDirectDistance')) {
                featureGeoJSON.properties.siteReportDirectDistance = 0
            }
            var featureCentroid = centroid(featureGeoJSON);
            var fromCentroid = centroid(fromGeoJSON);
            var directDistance = distance(featureCentroid, fromCentroid, { units: 'kilometers' });
            if (directDistance.toFixed(0) == 0) {
                directDistance = directDistance.toFixed(1)
            } else {
                directDistance = directDistance.toFixed(0)
            }

            featureGeoJSON.properties.siteReportDirectDistance = directDistance
            featureGeoJSON.properties.siteReportDirectDistanceFormatted = directDistance + 'km'
        }
    }

    //sort based on distance
    if (featuresGeoJSON.features[0] && featuresGeoJSON.features[0].properties) {
        let reSort = false;
        if (featuresGeoJSON.features[0].properties.hasOwnProperty('siteReportDirectDistance')) {
            reSort = true
        }
        while (reSort == true) {
            reSort = false
            for (var fi = 1; fi < featuresGeoJSON.features.length; fi++) {
                if (Number(featuresGeoJSON.features[fi].properties.siteReportDirectDistance) < Number(featuresGeoJSON.features[fi - 1].properties.siteReportDirectDistance)) {
                    //swap the feature order
                    const tempFeatureGeoJSON = featuresGeoJSON.features[fi - 1]
                    featuresGeoJSON.features[fi - 1] = featuresGeoJSON.features[fi]
                    featuresGeoJSON.features[fi] = tempFeatureGeoJSON
                    reSort = true
                    break;
                }
            }

        }
    }

    return featuresGeoJSON
}

/**
 * Displays the site search for to enable correct validation of site filters.
 */
function showSiteSearchForm() {
    //Create the section using a template to add the table into.
    var sectionName = "siteSearchForm"
    var sectionElementName = getHTMLCleanString(sectionName);
    var sectionElementHTML = getFormTemplate(sectionElementName, getUrlParam("report") || "da_public");  //Get the default section template HTML snippet.
    $(sectionElementHTML).appendTo("#reportBody"); //Add the HTML snippet to the DOM.

    $("#reportTitle").html(report.title + '<sup><small>©</small></sup>');

    window.document.title = report.title;
    $("#reportVersion").html('<b>Version Info</b>: Report-' + report.version + ', App-' + appVersion);
    $("#" + sectionElementName + "-head").html("Site Search"); //Add the title to the section

    var siteFilter = null;
    //Construct the GeoJSON feature collection, as terraformer only converts a single ArcGIS feature to GeoJSON.
    var searchFormItemCount = 0;
    for (var siteFilterIndex = 0; siteFilterIndex < report.siteInfo.siteFilters.length; siteFilterIndex++) {
        siteFilter = report.siteInfo.siteFilters[siteFilterIndex];

        if (siteFilter.useInSearchForm) {
            var useGeoLocation = false;
            if (navigator && navigator.geolocation && siteFilter.searchForm.enableLocationSearch) {
                useGeoLocation = true;
            }

            //Create the section using a template to add the table into.
            //Get the filter value from the querystring
            var filterValue = getUrlParam(siteFilter.parameterName);
            var siteFilterElementName = getHTMLCleanString(sectionElementName + "-SiteFilter" + siteFilterIndex);
            var siteFilterElementHTML = getFormItemTemplate(siteFilterElementName, siteFilter.searchForm.title, siteFilter.parameterName, siteFilter.searchForm.hint, useGeoLocation, filterValue);
            if (searchFormItemCount > 0)
                $('<strong>or</strong>').appendTo("#" + sectionElementName + "-body"); //Add the HTML snippet to the DOM.
            $(siteFilterElementHTML).appendTo("#" + sectionElementName + "-body"); //Add the HTML snippet to the DOM.

            if (useGeoLocation) { // && navigator.geolocation) {

                $("#" + siteFilterElementName + "-btnLocation").on('click', siteFilter, getLocationSites)


            }
            searchFormItemCount = searchFormItemCount + 1;


            //Add in the clear button and link up the click event to clear the text box.
            var siteFilterElementNameClearButton = siteFilterElementName + '-BtnClear';
            $("#" + siteFilterElementName + "-groupBtn").html(getIconButton(siteFilterElementNameClearButton, 'bi bi-x'));
            $("#" + siteFilterElementNameClearButton).on('click', siteFilter, function (e) {

                $("#" + e.data.parameterName).typeahead('destroy');

                $("#" + e.data.parameterName).val('');
                $("#" + e.data.parameterName).trigger('focus');
                var minLength = e.data.minLength || 2
                initialiseTypeAhead(e.data, minLength);
            });

            $("#" + siteFilterElementName + "-groupAddon").hide();

            var minLength = siteFilter.minLength || 2
            initialiseTypeAhead(siteFilter, minLength);

        }
    }
    $('<br><input id="btnSubmit" disabled="true" type="submit" align="right" value="View Report" class="btn btn-primary pull-right">').appendTo("#" + sectionElementName + "-body"); //Add the HTML snippet to the DOM.

}


function getMatches(checkString, regex) {
    var match;
    try {
        var jregex = new RegExp(regex);

        match = checkString.match(jregex, 'gi');//, 'i');
    }
    catch (err) {
        alert('This search type is not compatable with your browser, we suggest using Chrome.')
    }

    return match
}


function initialiseTypeAhead(siteFilter, minLength) {
    $("#btnSubmit").prop('disabled', true);
    $("#" + siteFilter.parameterName).typeahead(
        {
            minLength: minLength,
            autoSelect: true,
            siteFilter: siteFilter,

            source: function (request, response) {
                var queryFunction;
                var $items = new Array;
                $items = [""];
                $("#" + this.options.siteFilter.parameterName).data('typeahead').options.minLength = minLength;
                //compose the filter value statement for the query, taking into account using value arrays.
                var filterValue = request;
                var filterValueStatement = "";

                if (this.options.siteFilter.service == "WFS") {
                    //Replace text spaces with %
                    const searchText = '%' + filterValue.trim().replace(' ', '%') + '%';

                    filterValueStatement = this.options.siteFilter.searchForm.searchAttributeName + this.options.siteFilter.searchForm.compareOperatorText + this.options.siteFilter.searchForm.preValueText + searchText + this.options.siteFilter.searchForm.postValueText + this.options.siteFilter.searchForm.postCompareOperatorText;

                    if (this.options.siteFilter.searchForm.searchRegEx) {
                        let myRegEx = this.options.siteFilter.searchForm.searchRegEx;
                        // Get an array containing the first capturing group for every match
                        let matches = getMatches(filterValue.trim(), myRegEx);
                        if (matches) {
                            filterValueStatement = "";
                            for (let prop in matches.groups) {
                                if (filterValueStatement != "") {
                                    filterValueStatement = filterValueStatement + ' and ';
                                }
                                filterValueStatement = filterValueStatement + prop + this.options.siteFilter.searchForm.compareOperatorText + this.options.siteFilter.searchForm.preValueText + matches.groups[prop] + this.options.siteFilter.searchForm.postValueText + this.options.siteFilter.searchForm.postCompareOperatorText;
                            }
                        }
                    }

                    let options = {
                        "CQL_FILTER": filterValueStatement,
                        "sortby": this.options.siteFilter.searchForm.attributeName,
                        "typenames": this.options.siteFilter.layerId,
                        "maxFeatures": 500,
                        "count": 500
                    }

                    if (this.options.siteFilter.filter) {
                        options.cql_filter = options.cql_filter + ' and ' + this.options.siteFilter.filter
                    }
                    queryFunction = ogc.WFS.getFeature(this.options.siteFilter.url, options, this.options.siteFilter.layerName)

                } else if (this.options.siteFilter.service == "MapService") {
                    filterValueStatement = 'upper(' + this.options.siteFilter.searchForm.attributeName + ')' + this.options.siteFilter.searchForm.compareOperatorText + this.options.siteFilter.searchForm.preValueText + filterValue.trim().toUpperCase() + this.options.siteFilter.searchForm.postValueText + this.options.siteFilter.searchForm.postCompareOperatorText;

                    if (this.options.siteFilter.searchForm.searchRegEx) {
                        let myRegEx = this.options.siteFilter.searchForm.searchRegEx
                        // Get an array containing the first capturing group for every match
                        let matches = getMatches(filterValue.trim(), myRegEx);
                        if (matches) {
                            filterValueStatement = "";
                            for (var prop in matches.groups) {
                                if (filterValueStatement != "") {
                                    filterValueStatement = filterValueStatement + ' and ';
                                }
                                filterValueStatement = filterValueStatement + prop + this.options.siteFilter.searchForm.compareOperatorText + this.options.siteFilter.searchForm.preValueText + matches.groups[prop] + this.options.siteFilter.searchForm.postValueText + this.options.siteFilter.searchForm.postCompareOperatorText;
                            }
                        }
                    }

                    let options = {
                        "where": filterValueStatement,
                        "outFields": "*",
                        "orderByFields": this.options.siteFilter.searchForm.attributeName,
                        "returnGeometry": false,
                        "outSR": 4326,
                        "f": "pjson"
                    }
                    queryFunction = esri.MapService.query(this.options.siteFilter.url + this.options.siteFilter.layerId, options, this.options.siteFilter.layerName)
                }
                var siteFilter = this.options.siteFilter;

                queryFunction.done(function (data) {
                    var responseData = [];
                    if (siteFilter.service == "WFS") {
                        if (data.features) {
                            for (var featureIndex = 0; featureIndex <= data.features.length - 1; featureIndex++) {

                                //Replace field placeholders in Alias & Value Text elements with actual field value.
                                var properties = data.features[featureIndex].properties;
                                var responseValue = ""
                                var responseId = ""
                                if (data.features[featureIndex]) {
                                    responseId = data.features[featureIndex].properties[siteFilter.attributeName]
                                    responseValue = data.features[featureIndex].properties[siteFilter.searchForm.attributeName]
                                }
                                if (siteFilter.searchForm.attributeValue != null && siteFilter.searchForm.attributeValue != "") {
                                    responseValue = siteFilter.searchForm.attributeValue
                                    for (let propertyItemKey in properties) {
                                        let propertyValue = properties[propertyItemKey];
                                        if (propertyValue == null) {
                                            propertyValue = "";
                                        }
                                        else if (new Date(propertyValue) && propertyItemKey.toLowerCase().indexOf('date') != -1) {
                                            // if you get here then you have a valid date 
                                            propertyValue = new Date(propertyValue).toDateString();
                                        }

                                        let re = new RegExp('{' + propertyItemKey + '}', 'gi')
                                        if (re.test(siteFilter.searchForm.attributeValue)) {
                                            responseValue = responseValue.replace(re, propertyValue);
                                        }

                                    }
                                }

                                let responseItem = {
                                    id: responseId,
                                    value: responseValue,
                                    toString: function () {
                                        return JSON.stringify(this);
                                        //return this.app;
                                    },
                                    toLowerCase: function () {
                                        return this.value.toLowerCase();
                                    },
                                    indexOf: function () {
                                        return String.prototype.indexOf.apply(this.value, arguments);
                                    },
                                    replace: function () {
                                        var value = '';
                                        value += this.value;
                                        if (typeof (this.level) != 'undefined') {
                                            value += ' <span class="pull-right muted">';
                                            value += this.level;
                                            value += '</span>';
                                        }
                                        return String.prototype.replace.apply('<div >' + value + '</div>', arguments);
                                    },
                                    substr: function () {
                                        return String.prototype.substr.apply(this.value, arguments);
                                    }
                                }
                                $items.push(responseItem)
                                responseData.push(responseValue)

                                if (data.features.length == 1 && data.features[0].properties[siteFilter.searchForm.attributeName] == $("#" + siteFilter.parameterName)[0].value) {
                                    responseData = [];
                                    $items = [];
                                    $('.typeahead').typeahead(['hide']);

                                } else if (data.features.length == 1 && data.features[0].properties[siteFilter.searchForm.attributeName] != $("#" + siteFilter.parameterName)[0].value) {
                                    $("#btnSubmit").prop('disabled', true);
                                }
                            }
                            response($items);
                        }
                    } else {
                        if (data.features) {

                            for (let fidx = 0; fidx <= data.features.length; fidx++) {

                                if (data.features[fidx]) {
                                    let responseValue = data.features[fidx].attributes[siteFilter.searchForm.attributeName];
                                    let responseId = responseValue;
                                    let attributes = data.features[fidx].attributes;
                                    if (siteFilter.searchForm.attributeValue != null && siteFilter.searchForm.attributeValue != "") {
                                        responseValue = siteFilter.searchForm.attributeValue;
                                        for (let propertyItemKey in attributes) {
                                            let propertyValue = attributes[propertyItemKey];
                                            if (propertyValue == null) {
                                                propertyValue = "";
                                            }
                                            else if (new Date(propertyValue) && propertyItemKey.toLowerCase().indexOf('date') != -1) {
                                                // if you get here then you have a valid date 
                                                propertyValue = new Date(propertyValue).toDateString();
                                            }
                                            let re = new RegExp('{' + propertyItemKey + '}', 'gi');
                                            if (re.test(siteFilter.searchForm.attributeValue)) {
                                                responseValue = responseValue.replace(re, propertyValue);
                                            }
                                        }
                                    }
                                    let responseItem = {
                                        id: responseId,
                                        value: responseValue,
                                        toString: function () {
                                            return JSON.stringify(this);
                                            //return this.app;
                                        },
                                        toLowerCase: function () {
                                            return this.value.toLowerCase();
                                        },
                                        indexOf: function () {
                                            return String.prototype.indexOf.apply(this.value, arguments);
                                        },
                                        replace: function () {
                                            var value = '';
                                            value += this.value;
                                            if (typeof (this.level) != 'undefined') {
                                                value += ' <span class="pull-right muted">';
                                                value += this.level;
                                                value += '</span>';
                                            }
                                            return String.prototype.replace.apply('<div >' + value + '</div>', arguments);
                                        },
                                        substr: function () {
                                            return String.prototype.substr.apply(this.value, arguments);
                                        }
                                    };

                                    $items.push(responseItem);
                                    responseData.push(responseValue);
                                }

                                if (data.features.length == 1 && data.features[0].attributes[siteFilter.searchForm.attributeName] == $("#" + siteFilter.parameterName)[0].value) {
                                    responseData = [];
                                    $('.typeahead').typeahead(['hide']);

                                } else if (data.features.length == 1 && data.features[0].attributes[siteFilter.searchForm.attributeName] != $("#" + siteFilter.parameterName)[0].value) {
                                    $("#btnSubmit").prop('disabled', true);
                                }
                            }
                            response($items);
                        }
                    }
                });

            },
            updater: function (item) {
                $("#btnSubmit").prop('disabled', false);
                $("#btnSubmit").trigger('focus');

                return item.id;
            },

            highlighter: function (item) {
                // eslint-disable-next-line no-useless-escape
                var query = this.query.replace(/[\-\[\]{}()*+?.,\\\^$|#\s]/g, '\\$&');
                return item.replace(new RegExp('(' + query + ')', 'ig'),
                    function ($1, match) {
                        return '<strong>' + match + '</strong>';
                    });
            }
        });

}

/**
 * Identify site selection text using the GPS location
 * @param data
 */
function getLocationSites(options) {
    var siteFilter = options.data;
    var minLength = siteFilter.minLength || 2;

    initialiseTypeAhead(siteFilter, minLength);

    var results = [];
    //get the GPS details

    if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (position) {
            var latitude = position.coords.latitude; //-26.662034; //position.coords.latitude;
            var longitude = position.coords.longitude; //153.100800; //position.coords.longitude;
            var accuracy = position.coords.accuracy;

            if (accuracy >= 500) {
                $("#reportProgress").html('<strong>Location Information:</strong><br>We cannot be sure of your GPS location. Please move to obtain a better GPS signal and try again.');
                $('#reportProgress').show();
                //showSiteSearchForm();
            }

            var location = {
                'type': 'Feature',
                'geometry': {
                    'type': 'Point',
                    'coordinates': [longitude, latitude]
                },
                'properties': {
                    'name': 'User Location'
                }
            }

            //calculate a buffer distance around the lat/long using the accuracy value.
            var locationBuffer = buffer(location, accuracy, { units: 'meters' });



            //Query the site filter using the geometry
            switch (siteFilter.service) {
                case "WFS": {
                    var wktformat = new WKT();
                    var geojsonformat = new GeoJSON();
                    var geometry = geojsonformat.readGeometry(flipGeoJSON(locationBuffer.geometry));

                    var wktGeometry = wktformat.writeGeometry(geometry);

                    var geometryName = siteFilter.geometryName || "Shape";
                    var options = {
                        "typenames": siteFilter.layerId,
                        "cql_filter": "INTERSECTS(" + geometryName + ", " + wktGeometry + ")"
                        //"format_options":"callback:getJson",

                    };

                    if (siteFilter.filter) {
                        options.cql_filter = options.cql_filter + ' and ' + siteFilter.filter;
                    }

                    var resultDataFunction = ogc.WFS.getFeature(siteFilter.url, options, siteFilter.layerName);

                    resultDataFunction.done(function (data) {
                        var responseData = [];
                        if (data.features) {

                            for (var featureIndex = 0; featureIndex <= data.features.length; featureIndex++) {
                                if (data.features[featureIndex]) {
                                    let responseValue = data.features[featureIndex].properties[siteFilter.searchForm.attributeName];
                                    let responseId = responseValue;
                                    let attributes = data.features[featureIndex].properties;
                                    if (siteFilter.searchForm.attributeValue != null && siteFilter.searchForm.attributeValue != "") {
                                        responseValue = siteFilter.searchForm.attributeValue;
                                        for (var propertyItemKey in attributes) {
                                            let propertyValue = attributes[propertyItemKey];
                                            if (propertyValue == null) {
                                                propertyValue = "";
                                            }
                                            else if (new Date(propertyValue) && propertyItemKey.toLowerCase().indexOf('date') != -1) {
                                                // if you get here then you have a valid date 
                                                propertyValue = new Date(propertyValue).toDateString();
                                            }
                                            var re = new RegExp('{' + propertyItemKey + '}', 'gi');
                                            if (re.test(siteFilter.searchForm.attributeValue)) {
                                                responseValue = responseValue.replace(re, propertyValue);
                                            }
                                        }
                                    }
                                    let responseItem = {
                                        id: responseId,
                                        value: responseValue,
                                        toString: function () {
                                            return JSON.stringify(this);
                                            //return this.app;
                                        },
                                        toLowerCase: function () {
                                            return this.value.toLowerCase();
                                        },
                                        indexOf: function () {
                                            return String.prototype.indexOf.apply(this.value, arguments);
                                        },
                                        replace: function () {
                                            return String.prototype.replace.apply(this.value, arguments);
                                        },
                                        substr: function () {
                                            return String.prototype.substr.apply(this.value, arguments);
                                        }
                                    };

                                    responseData.push(responseItem);
                                }

                                if (data.features.length == 1 && data.features[0].properties[siteFilter.searchForm.attributeName] == $("#" + siteFilter.parameterName)[0].value) {
                                    responseData = [];
                                    $('.typeahead').typeahead(['destroy']);

                                } else if (data.features.length == 1 && data.features[0].properties[siteFilter.searchForm.attributeName] != $("#" + siteFilter.parameterName)[0].value) {
                                    $("#btnSubmit").prop('disabled', true);
                                }
                            }

                            $("#" + siteFilter.parameterName).data('typeahead').source = responseData;
                            $("#" + siteFilter.parameterName).data('typeahead').options.minLength = 0;

                            $("#" + siteFilter.parameterName).trigger('focus');
                            $("#" + siteFilter.parameterName).val('');
                        }

                    });

                    break;
                }
                case "MapService": {
                    let esriGeometry = geojsonToArcGIS(locationBuffer.geometry);
                    let options = {
                        "geometry": JSON.stringify(esriGeometry),
                        "geometryType": 'esriGeometryPolygon',
                        "inSR": 4326,
                        "spatialRel": 'esriSpatialRelIntersects',
                        "outFields": "*",
                        "outSR": 4326,
                        "orderByFields": siteFilter.searchForm.attributeName,
                        "f": "pjson"
                    }
                    let queryFunction = esri.MapService.query(siteFilter.url + siteFilter.layerId, options, siteFilter.layerName);
                    queryFunction.fail(function () {
                        $("#reportProgress").html('<strong>Information:</strong> I\'m sorry, but I couldn\'t find the site location.<br><strong>Please check the details below and re-submit.</strong>');
                        $('#reportProgress').show();
                    });
                    queryFunction.done(function (data) {

                        var responseData = [];
                        if (data.features) {

                            for (var featureIndex = 0; featureIndex <= data.features.length; featureIndex++) {
                                if (data.features[featureIndex]) {
                                    let responseValue = data.features[featureIndex].attributes[siteFilter.searchForm.attributeName];
                                    let responseId = responseValue;
                                    let attributes = data.features[featureIndex].attributes;
                                    if (siteFilter.searchForm.attributeValue != null && siteFilter.searchForm.attributeValue != "") {
                                        responseValue = siteFilter.searchForm.attributeValue;
                                        for (var propertyItemKey in attributes) {
                                            let propertyValue = attributes[propertyItemKey];
                                            if (propertyValue == null) {
                                                propertyValue = "";
                                            }
                                            else if (new Date(propertyValue) && propertyItemKey.toLowerCase().indexOf('date') != -1) {
                                                // if you get here then you have a valid date 
                                                propertyValue = new Date(propertyValue).toDateString();
                                            }
                                            var re = new RegExp('{' + propertyItemKey + '}', 'gi');
                                            if (re.test(siteFilter.searchForm.attributeValue)) {
                                                responseValue = responseValue.replace(re, propertyValue);
                                            }
                                        }
                                    }
                                    let responseItem = {
                                        id: responseId,
                                        value: responseValue,
                                        toString: function () {
                                            return JSON.stringify(this);
                                            //return this.app;
                                        },
                                        toLowerCase: function () {
                                            return this.value.toLowerCase();
                                        },
                                        indexOf: function () {
                                            return String.prototype.indexOf.apply(this.value, arguments);
                                        },
                                        replace: function () {
                                            return String.prototype.replace.apply(this.value, arguments);
                                        },
                                        substr: function () {
                                            return String.prototype.substr.apply(this.value, arguments);
                                        }
                                    };

                                    responseData.push(responseItem);
                                }

                                if (data.features.length == 1 && data.features[0].attributes[siteFilter.searchForm.attributeName] == $("#" + siteFilter.parameterName)[0].value) {
                                    responseData = [];
                                    $('.typeahead').typeahead(['destroy']);

                                } else if (data.features.length == 1 && data.features[0].attributes[siteFilter.searchForm.attributeName] != $("#" + siteFilter.parameterName)[0].value) {
                                    $("#btnSubmit").prop('disabled', true);
                                }
                            }

                            $("#" + siteFilter.parameterName).data('typeahead').source = responseData;
                            $("#" + siteFilter.parameterName).data('typeahead').options.minLength = 0;

                            $("#" + siteFilter.parameterName).trigger('focus');
                            $("#" + siteFilter.parameterName).val('');
                        }
                    });
                    break;
                }
                default:
                    break;
            }

        }, function error() {
            alert('We are unable to access your location.  Please check that location services are enabled on your device.');

        }, { maximumAge: 600000, timeout: 5000, enableHighAccuracy: true });
    }

    return results;
}

/**
 * Identify the site location using the report site filters, calling the callback function upon successful response.
 * @param callback - called upon successful response and parses the result as the first parameter in GeoJSON format.
 */
function identifySiteLocation(callback) {
    var siteFilter = null;
    //Construct the GeoJSON feature collection, as terraformer only converts a single ArcGIS feature to GeoJSON.
    var resultGeoJSON = {
        type: "FeatureCollection",
        features: []
    }

    var bufferDistance = getUrlParam('distance');

    if (getUrlParam('geojson')) {
        var inputGeoJSON = JSON.parse(getUrlParam('geojson'));
        var reducedPrecissionGeoJSON = JSON.stringify(inputGeoJSON, function (key, val) {
            return val.toFixed ? Number(val.toFixed(6)) : val;
        })
        var customGeoJSON = JSON.parse(reducedPrecissionGeoJSON);

        if (bufferDistance) {
            var featureGeoJSONBuffer = buffer(customGeoJSON, bufferDistance, { units: 'meters' })
            var reducedPrecissionGeoJSONBuffer = JSON.stringify(featureGeoJSONBuffer, function (key, val) {
                return val.toFixed ? Number(val.toFixed(6)) : val;
            })
            featureGeoJSONBuffer = JSON.parse(reducedPrecissionGeoJSONBuffer);
            for (var featureIndex = 0; featureIndex < featureGeoJSONBuffer.features.length; featureIndex++) {
                featureGeoJSONBuffer.features[featureIndex] = simplify(featureGeoJSONBuffer.features[featureIndex], 0.00001, true);
            }
            resultGeoJSON = featureGeoJSONBuffer;
        } else {
            if (customGeoJSON.type.toLowerCase() == 'featurecollection') {
                resultGeoJSON = customGeoJSON
            } else {
                resultGeoJSON.features.push(customGeoJSON);
            }
        }
        if (resultGeoJSON.features.length >= 0) {
            hasFilterValue = true;
            callback(resultGeoJSON);
        }

    } else {

        var promiseCount = 0;
        var hasFilterValue = false;


        for (var index = 0; index < report.siteInfo.siteFilters.length; index++) {
            siteFilter = report.siteInfo.siteFilters[index];
            bufferDistance = bufferDistance ? bufferDistance : siteFilter.geometryBufferDistance

            //Get the filter value from the querystring
            var filterValue = getUrlParam(siteFilter.parameterName);

            if (filterValue && filterValue.length > 0) {
                hasFilterValue = true;
                switch (siteFilter.service) {
                    case "WFS": {
                        //compose the filter value statement for the query, taking into account using value arrays.
                        let filterValueStatement = '';
                        promiseCount = promiseCount + 1;
                        for (let filterValueIndex = 0; filterValueIndex < filterValue.split(",").length; filterValueIndex++) {
                            if (filterValueStatement.length > 0) {
                                filterValueStatement = filterValueStatement + ",";
                            }
                            filterValueStatement = filterValueStatement + siteFilter.preValueText + filterValue.split(",")[filterValueIndex].trim() + siteFilter.postValueText;
                        }

                        let options = {
                            "typenames": siteFilter.layerId,
                            "cql_filter": siteFilter.attributeName + siteFilter.compareOperatorText + filterValueStatement + siteFilter.postCompareOperatorText,
                        }

                        if (siteFilter.filter) {
                            options.cql_filter = options.cql_filter + ' and ' + siteFilter.filter
                        }

                        let queryFunction = ogc.WFS.getFeature(siteFilter.url, options, siteFilter.layerName);
                        queryFunction.fail(function () {
                            promiseCount = promiseCount - 1;

                            if (promiseCount <= 0) {
                                //Call the callback function parsing the results.
                                if (resultGeoJSON.features.length == 0) {
                                    $("#reportProgress").html('<strong>Information:</strong> I\'m sorry, but I couldn\'t find the site location.<br><strong>Please check the details below and re-submit.</strong>');
                                    $('#reportProgress').show();
                                    showSiteSearchForm();
                                }
                            }
                        });
                        queryFunction.done(function (data) {
                            for (let featureIndex = 0; featureIndex < data.features.length; featureIndex++) {
                                let featureGeoJSON = data.features[featureIndex]
                                //allow for multi-geom features
                                if (featureGeoJSON.geometry_name != siteFilter.geometryName) {
                                    featureGeoJSON.geometry_name = siteFilter.geometryName
                                    featureGeoJSON.geometry = featureGeoJSON.properties[siteFilter.geometryName]
                                }
                                let bufferUnion;
                                for (let coordsIndex = 0; coordsIndex < featureGeoJSON.geometry.coordinates.length; coordsIndex++) {

                                    let processCoords = featureGeoJSON.geometry.coordinates[coordsIndex];

                                    let processFeature = {
                                        properties: featureGeoJSON.properties,
                                        type: featureGeoJSON.type,
                                        geometry: $.extend({}, featureGeoJSON.geometry)
                                    };

                                    processFeature.geometry.coordinates = [processCoords]
                                    try {
                                        let featureGeoJSONBufferCalc = buffer(processFeature, bufferDistance, { units: 'meters' })
                                        if (featureGeoJSONBufferCalc.type == 'Feature') {
                                            featureGeoJSONBuffer = {};
                                            featureGeoJSONBuffer.type = 'FeatureCollection';
                                            featureGeoJSONBuffer.features = [featureGeoJSONBufferCalc]
                                        } else {
                                            featureGeoJSONBuffer = featureGeoJSONBufferCalc;
                                        }

                                        for (let bufferFeatureIndex = 0; bufferFeatureIndex < featureGeoJSONBuffer.features.length; bufferFeatureIndex++) {
                                            try {
                                                featureGeoJSONBuffer.features[bufferFeatureIndex] = simplify(featureGeoJSONBuffer.features[bufferFeatureIndex], 0.000001, true);
                                            }
                                            catch (err) {
                                                //There was an error in buffering the geomtry
                                            }
                                        }
                                    }
                                    catch (err) {
                                        let featureGeoJSONBuffer = {}
                                        featureGeoJSONBuffer.features = []
                                        featureGeoJSONBuffer.features[0] = processFeature
                                        featureGeoJSONBuffer.type = 'FeatureCollection'
                                    }

                                    if (!bufferUnion) {
                                        bufferUnion = $.extend({}, featureGeoJSONBuffer.features[0]);
                                    } else {
                                        bufferUnion = union(bufferUnion, featureGeoJSONBuffer.features[0]);
                                    }
                                }

                                let reducedPrecissionGeoJSONBuffer = JSON.stringify(bufferUnion, function (key, val) {
                                    if (val) {
                                        return val.toFixed ? Number(val.toFixed(7)) : val;
                                    } else {
                                        return val
                                    }
                                })

                                reducedPrecissionGeoJSONBuffer = JSON.parse(reducedPrecissionGeoJSONBuffer);

                                if (reducedPrecissionGeoJSONBuffer.geometry) {
                                    data.features[featureIndex].geometry = reducedPrecissionGeoJSONBuffer.geometry; //esriJSONBuffer.geometry;
                                }
                            }

                            promiseCount = promiseCount - 1;
                            if (data.features.length == 0) {

                                //no features found, so show the validation form
                                if (promiseCount <= 0) {
                                    //Call the callback function parsing the results.

                                    if (resultGeoJSON.features.length == 0) {
                                        $("#reportProgress").html('<strong>Information:</strong> I\'m sorry, but I couldn\'t find the site location.<br><strong>Please check the details below and re-submit.</strong>');
                                        $('#reportProgress').show();
                                        showSiteSearchForm();
                                    }
                                }

                            }

                            for (let featureIndex = 0; featureIndex < data.features.length; featureIndex++) {
                                let feature = data.features[featureIndex]; // .toJSON();
                                feature.id = featureIndex;

                                resultGeoJSON.features.push(feature);
                            }

                            if (promiseCount == 0) {
                                //Call the callback function parsing the results.

                                if (resultGeoJSON.features.length >= 0) {
                                    resultGeoJSON = appendDistanceProperties(resultGeoJSON, resultGeoJSON)
                                    resultGeoJSON = appendLatLongProperties(resultGeoJSON)

                                    //Include the markerID values.
                                    for (var fidx = 0; fidx < resultGeoJSON.features.length; fidx++) {
                                        resultGeoJSON.features[fidx].properties.siteReportMarkerId = fidx + 1
                                    }
                                    callback(resultGeoJSON);
                                }
                            }

                        });
                        break;
                    }
                    case "MapService": {
                        //compose the filter value statement for the query, taking into account using value arrays.
                        let filterValueStatement = "";
                        promiseCount = promiseCount + 1;
                        for (let filterValueIndex = 0; filterValueIndex < filterValue.split(",").length; filterValueIndex++) {
                            if (filterValueStatement.length > 0) {
                                filterValueStatement = filterValueStatement + ",";
                            }
                            filterValueStatement = filterValueStatement + siteFilter.preValueText + filterValue.split(",")[filterValueIndex].trim() + siteFilter.postValueText;
                        }

                        let options = {
                            "where": siteFilter.attributeName + siteFilter.compareOperatorText + filterValueStatement + siteFilter.postCompareOperatorText,
                            "outFields": "*",
                            "outSR": 4326,
                            "f": "pjson"
                        }
                        let queryFunction = esri.MapService.query(siteFilter.url + siteFilter.layerId, options, siteFilter.layerName);
                        queryFunction.fail(function () {

                            promiseCount = promiseCount - 1;

                            if (promiseCount <= 0) {
                                //Call the callback function parsing the results.
                                if (resultGeoJSON.features.length == 0) {
                                    $("#reportProgress").html('<strong>Information:</strong> I\'m sorry, but I couldn\'t find the site location.<br><strong>Please check the details below and re-submit.</strong>');
                                    $('#reportProgress').show();
                                    showSiteSearchForm();
                                }
                            }
                        });
                        queryFunction.done(function (data) {
                            for (let fidx = 0; fidx < data.features.length; fidx++) {
                                let featureGeoJSON = arcgisToGeoJSON(data.features[fidx]);
                                //allow for multi-geom features
                                let bufferUnion;
                                for (let coordsIndex = 0; coordsIndex < featureGeoJSON.geometry.coordinates.length; coordsIndex++) {

                                    let processCoords = featureGeoJSON.geometry.coordinates[coordsIndex];
                                    let processFeature = {
                                        properties: featureGeoJSON.properties,
                                        type: featureGeoJSON.type,
                                        geometry: $.extend({}, featureGeoJSON.geometry)
                                    };
                                    processFeature.geometry.coordinates = [processCoords]
                                    let featureGeoJSONBufferCalc = buffer(processFeature, bufferDistance, { units: 'meters' })
                                    if (featureGeoJSONBufferCalc.type == 'Feature') {
                                        featureGeoJSONBuffer = {};
                                        featureGeoJSONBuffer.type = 'FeatureCollection';
                                        featureGeoJSONBuffer.features = [featureGeoJSONBufferCalc]
                                    } else {
                                        featureGeoJSONBuffer = featureGeoJSONBufferCalc;
                                    }

                                    for (let bufferFeatureIndex = 0; bufferFeatureIndex < featureGeoJSONBuffer.features.length; bufferFeatureIndex++) {
                                        featureGeoJSONBuffer.features[bufferFeatureIndex] = simplify(featureGeoJSONBuffer.features[bufferFeatureIndex], { tolerance: 0.00001, highQuality: true });
                                    }

                                    if (!bufferUnion) {
                                        bufferUnion = $.extend({}, featureGeoJSONBuffer.features[0]);
                                    } else {
                                        bufferUnion = union(bufferUnion, featureGeoJSONBuffer.features[0]);
                                    }
                                }

                                var reducedPrecissionGeoJSONBuffer = JSON.stringify(bufferUnion, function (key, val) {
                                    if (val) {
                                        return val.toFixed ? Number(val.toFixed(6)) : val;
                                    } else {
                                        return val
                                    }
                                })

                                var esriJSONBuffer = geojsonToArcGIS(JSON.parse(reducedPrecissionGeoJSONBuffer));
                                data.features[fidx].geometry = esriJSONBuffer.geometry;
                            }

                            promiseCount = promiseCount - 1;
                            if (data.features.length == 0) {

                                //no features found, so show the validation form
                                if (promiseCount <= 0) {
                                    //Call the callback function parsing the results.

                                    if (resultGeoJSON.features.length == 0) {
                                        $("#reportProgress").html('<strong>Information:</strong> I\'m sorry, but I couldn\'t find the site location.<br><strong>Please check the details below and re-submit.</strong>');
                                        $('#reportProgress').show();
                                        showSiteSearchForm();
                                    }
                                }

                            }

                            for (var featureIndex = 0; featureIndex < data.features.length; featureIndex++) {
                                var feature = arcgisToGeoJSON(data.features[featureIndex]);//new Terraformer.ArcGIS.parse(data.features[featureIndex]).toJSON();
                                feature.id = featureIndex;

                                resultGeoJSON.features.push(feature);
                            }


                            if (promiseCount == 0) {
                                //Call the callback function parsing the results.
                                if (resultGeoJSON.features.length >= 0) {
                                    resultGeoJSON = appendDistanceProperties(resultGeoJSON, resultGeoJSON)
                                    resultGeoJSON = appendLatLongProperties(resultGeoJSON)

                                    //Include the markerID values.
                                    for (var fidx = 0; fidx < resultGeoJSON.features.length; fidx++) {
                                        resultGeoJSON.features[fidx].properties.siteReportMarkerId = fidx + 1
                                    }
                                    callback(resultGeoJSON);
                                }
                            }

                        });
                        break;
                    }
                    default:
                        $("#reportProgress").html('<strong>Information:</strong> I\'m sorry, But it appears as though this report is not configured correctly.<br><strong>Please contact the site administrator to correct this report.</strong>');
                        $('#reportProgress').show();

                }
                //break;  //a filter parameter was found, so exit the loop.
            }


        }
    }

    if (!hasFilterValue) {
        $("#reportProgress").html('<strong>Information:</strong> Please enter the site location details below. ' + report.siteInfo.siteFilterMessage);
        $('#reportProgress').show();
        showSiteSearchForm();
    }
}

/**
 * Update the drill down information section in the report, generating from the drillDownInfo element report configurations.
 * @param siteGeoJSON - the result from applying the site filter using the identitySiteLocation function - in GeoJSON format.
 * @param reportConfig - created from evaluating the report configuration file.  Defaults to report if not specified.
 */
async function showDrillDownInformation(siteGeoJSON, reportConfig, sectionName) {

    siteGeoJSON = appendLatLongProperties(siteGeoJSON)

    reportConfig = reportConfig || report;
    var reportConfigSection = reportConfig[sectionName];

    if (siteGeoJSON.features.length > 0 && reportConfigSection) {
        for (var drillDownInfoElementIndex = 0; drillDownInfoElementIndex < reportConfigSection.elements.length; drillDownInfoElementIndex++) {
            var drillDownInfoElement = reportConfigSection.elements[drillDownInfoElementIndex];
            switch (drillDownInfoElement.type) {
                case "table":
                    //Create the section using a template to add the table into.
                    var sectionElementName = getHTMLCleanString(sectionName + "-element" + drillDownInfoElementIndex);
                    var sectionElementHTML = getSectionTemplate(sectionElementName);  //Get the default section template HTML snippet.
                    $(sectionElementHTML).appendTo("#drilldownDiv"); //Add the HTML snippet to the DOM.
                    $("#" + sectionElementName + "-title").html(drillDownInfoElement.title); //Add the title to the section
                    $("#" + sectionElementName + "-desc").html(drillDownInfoElement.description); //Add the description to the section

                    //Create a new section table for each site feature
                    for (var siteFeatureIndex = 0; siteFeatureIndex < siteGeoJSON.features.length; siteFeatureIndex++) {

                        //Create the section using a template to add the table into.
                        var featureElementName = getHTMLCleanString(sectionElementName + "-feature" + siteFeatureIndex);
                        var featureElementHTML = getTableTemplate(featureElementName);  //Get the default section template HTML snippet.
                        $(featureElementHTML).appendTo("#" + sectionElementName + "-body"); //Add the HTML snippet to the DOM.

                        if (siteGeoJSON.features.length > 1) { //If only one feature, don't show the feature Title.
                            $("#" + featureElementName + "-head").append("<tr><td colspan='2'><b>" + reportConfig.drillDownInfo.multiSitePrefix + " " + (siteFeatureIndex + 1) + "</b><span id='multiFeatureDefaultValue-" + siteFeatureIndex + "'></span> </td></tr>");
                        }

                        var mapFeatureElementName = getHTMLCleanString(sectionElementName + "-feature" + siteFeatureIndex + "Map");
                        var mapFeatureElementHTML = getDivTemplate(mapFeatureElementName);  //Get the default section template HTML snippet.
                        $(mapFeatureElementHTML).appendTo("#" + sectionElementName + "-body"); //Add the HTML snippet to the DOM.

                        for (var layerIndex = 0; layerIndex < drillDownInfoElement.layers.length; layerIndex++) {

                            //Add the placeholder for the layer to retain the ordering.
                            var layerPlaceholderElementName = getHTMLCleanString(featureElementName + '-layer' + layerIndex);
                            if ($("#" + layerPlaceholderElementName).length == 0) {
                                $("#" + featureElementName + "-body").append("<div id='" + layerPlaceholderElementName + "'></div>");
                            }

                            //Add the placeholder for the map layer to retain the ordering.
                            var mapLayerPlaceholderElementName = getHTMLCleanString(mapFeatureElementName + '-layer' + layerIndex);
                            if ($("#" + mapLayerPlaceholderElementName).length == 0) {
                                $("#" + mapFeatureElementName).append("<div id='" + mapLayerPlaceholderElementName + "'></div>");
                            }

                            let drillDownInfoLayer = populateLayerDefaults(drillDownInfoElement, drillDownInfoElement.layers[layerIndex]);

                            let geometryIntersectQueryLayers = [];
                            if (drillDownInfoLayer.geometryIntersectQueryLayers?.length > 0) {
                                for (const gdl of drillDownInfoLayer.geometryIntersectQueryLayers) {
                                    geometryIntersectQueryLayers.push(gdl);
                                }
                            } else {
                                geometryIntersectQueryLayers.push(drillDownInfoLayer);
                            }

                            for (const geometryIntersectQueryLayer of geometryIntersectQueryLayers) {
                                
                                const responseDrillDownGeoJSON = await performGeometryDrillDown(siteGeoJSON, geometryIntersectQueryLayer, featureElementName, siteFeatureIndex, layerPlaceholderElementName, mapLayerPlaceholderElementName);

                                let fieldItemTemplate = null;

                                if (responseDrillDownGeoJSON?.features && responseDrillDownGeoJSON.features.length > 0) {
                                    let properties = responseDrillDownGeoJSON.features[0].properties;

                                    for (let propertyIndex = 0; propertyIndex < Object.keys(properties).length; propertyIndex++) {
                                        let key = Object.keys(properties)[propertyIndex];

                                        //get the display field item that matches the properties key.  This is to get the alias and display fields.
                                        for (let displayFieldIndex = 0; displayFieldIndex < drillDownInfoLayer.displayFields.length; displayFieldIndex++) {
                                            let displayFieldItem = drillDownInfoLayer.displayFields[displayFieldIndex];

                                            if (displayFieldItem.valueText && propertyIndex == 0) { //only do for the first field, otherwise one for each is returned
                                                let displayFieldKeys = Object.keys(displayFieldItem);
                                                fieldItemTemplate = {};
                                                for (let displayFieldKeyIndex = 0; displayFieldKeyIndex < displayFieldKeys.length; displayFieldKeyIndex++) {
                                                    fieldItemTemplate[displayFieldKeys[displayFieldKeyIndex]] = displayFieldItem[displayFieldKeys[displayFieldKeyIndex]];
                                                }
                                                break;
                                            } else if (displayFieldItem.fields != null) {
                                                let displayFieldNames = displayFieldItem.fields.toUpperCase().split(",");
                                                for (let displayFieldNameIndex = 0; displayFieldNameIndex < displayFieldNames.length; displayFieldNameIndex++) {
                                                    if (displayFieldNames[displayFieldNameIndex].trim().toUpperCase() == (key.trim().toUpperCase())) {
                                                        let displayFieldKeys = Object.keys(displayFieldItem);
                                                        fieldItemTemplate = {};
                                                        for (let displayFieldKeyIndex = 0; displayFieldKeyIndex < displayFieldKeys.length; displayFieldKeyIndex++) {
                                                            fieldItemTemplate[displayFieldKeys[displayFieldKeyIndex]] = displayFieldItem[displayFieldKeys[displayFieldKeyIndex]];
                                                        }
                                                        break;
                                                    }
                                                }
                                            }
                                        }
                                    }

                                    // process if a result was returned
                                    for (let drillDownFeatureIndex = 0; drillDownFeatureIndex < responseDrillDownGeoJSON.features.length; drillDownFeatureIndex++) {
                                        let properties = responseDrillDownGeoJSON.features[drillDownFeatureIndex].properties;

                                        let fieldItem = $.extend({}, fieldItemTemplate);
                                        //for each field/property returned from the service, find the appropriate displayfeilds from the config file and serve results.
                                        for (var propertyIndex = 0; propertyIndex < Object.keys(properties).length; propertyIndex++) {
                                            var key = Object.keys(properties)[propertyIndex];

                                            //Display the fieldItem values and alias in the report.
                                            if (report.status == "development" || fieldItem) {

                                                //only process the properties that are required.
                                                let inlineFieldPlaceholderRegExp = new RegExp('{' + key + '}', 'gi');
                                                let fieldNameRegExp = new RegExp(key, 'gi');
                                                if (inlineFieldPlaceholderRegExp.test(fieldItem.valueText) || fieldNameRegExp.test(fieldItem.fields) || fieldNameRegExp.test(fieldItem.aliasField) || fieldNameRegExp.test(fieldItem.alias)) {

                                                    if (fieldItem == null) {
                                                        //if the fielditem is null, ie for development purposes, show all fields.
                                                        fieldItem = {
                                                            displayGroup: key + " (Note: Shown for Development Purposes Only)",
                                                            fields: key
                                                        };
                                                    }

                                                    let fieldValues = null;

                                                    if (fieldItem.fields != null && fieldItem.fields != "") {
                                                        //Concat the field values into a single string for multi field name fielditems.
                                                        let fieldItemFieldNames = fieldItem.fields.split(",");
                                                        for (let fieldValueIndex = 0; fieldValueIndex < fieldItemFieldNames.length; fieldValueIndex++) {
                                                            if (properties.hasOwnProperty(fieldItemFieldNames[fieldValueIndex])) {
                                                                if (fieldValues != null) {
                                                                    fieldValues = fieldValues + ' ' + properties[fieldItemFieldNames[fieldValueIndex]];
                                                                } else {
                                                                    fieldValues = properties[fieldItemFieldNames[fieldValueIndex]];
                                                                    fieldItem.propertyValue = properties[fieldItemFieldNames[fieldValueIndex]];
                                                                }
                                                            }
                                                        }
                                                    }
                                                    fieldItem.displayGroup = fieldItemTemplate.displayGroup
                                                    //Write the field alias and values to the DOM table.
                                                    if (fieldItem.displayGroup == null || fieldItem.displayGroup == "") {
                                                        if (fieldItemTemplate.aliasField != null && fieldItemTemplate.aliasField != "") {
                                                            fieldItem.displayGroup = properties[fieldItemTemplate.aliasField];
                                                        } else if (fieldItemTemplate.alias != null && fieldItemTemplate.alias != "") {
                                                            fieldItem.displayGroup = fieldItemTemplate.alias;
                                                        }
                                                    }

                                                    //Replace field placeholders in Alias & Value Text elements with actual field value.
                                                    for (var propertyItemKey in properties) {
                                                        let propertyValue = properties[propertyItemKey];
                                                        if (propertyValue == null) {
                                                            propertyValue = "";
                                                        }
                                                        else if (new Date(propertyValue) && propertyItemKey.toLowerCase().indexOf('date') != -1) {
                                                            // if you get here then you have a valid date 
                                                            propertyValue = new Date(propertyValue).toDateString();
                                                        }

                                                        var re = new RegExp('{' + propertyItemKey + '}', 'gi')
                                                        if (fieldItem.valueText != null && fieldItem.valueText != "" && re.test(fieldItem.valueText)) {
                                                            fieldItem.valueText = fieldItem.valueText.replace(re, propertyValue);
                                                        }
                                                        if (fieldItem.displayGroup && re.test(fieldItem.displayGroup)) {
                                                            fieldItem.displayGroup = fieldItem.displayGroup.replace(re, propertyValue);
                                                        }
                                                    }

                                                    if ((fieldItem.valueText != null && fieldItem.valueText != "") && fieldValues != null) {
                                                        let re = new RegExp('{fieldValue}', 'gi');
                                                        fieldValues = fieldItem.valueText.replace(re, fieldValues);
                                                    } else if (fieldItem.valueText != null && fieldItem.valueText != "") {
                                                        fieldValues = fieldItem.valueText;
                                                    }

                                                    //add the row title when processing the first returned record.
                                                    if ((fieldValues != null && drillDownInfoLayer.displayWhen == null) ||
                                                        (fieldValues != null && drillDownInfoLayer.displayWhen == 'result') ||
                                                        (fieldValues == null && drillDownInfoLayer.displayWhen == 'no result') ||
                                                        (drillDownInfoLayer.displayWhen == 'always')
                                                    ) {
                                                        //Initalise storage to display group values
                                                        if (!insertedLayerValues[featureElementName]) {
                                                            insertedLayerValues[featureElementName] = {};
                                                        }

                                                        if (!insertedLayerValues[featureElementName].hasOwnProperty(fieldItem.displayGroup)) {
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup] = {};
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].count = 0;
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].layers = [];
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].mapElementNames = [];
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].display = drillDownInfoLayer.display;

                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items = {};
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].value = '';
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].type = 'Custom';

                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].list = {};
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].list.count = 0;
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].list.value = '';
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].list.type = "List";

                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].summary = {};
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].summary.total = {};
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].summary.total.count = 0;
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].summary.total.value = 0;
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].summary.total.type = "Total";
                                                        }

                                                        //Initalise storage for Item values
                                                        if (!insertedLayerValues[featureElementName][fieldItem.displayGroup].items.hasOwnProperty(fieldValues)) {
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues] = {};
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].count = 0;
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].value = '';
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].type = "List";
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].summary = {};
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].summary.total = {};
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].summary.total.count = 0;
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].summary.total.value = 0;
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].summary.total.type = "Total";
                                                        }

                                                        if (insertedLayerValues[featureElementName][fieldItem.displayGroup].layers.indexOf(drillDownInfoLayer) == -1) {
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].layers.push(drillDownInfoLayer);
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].mapElementNames.push(mapLayerPlaceholderElementName)
                                                        }

                                                        insertedLayerValues[featureElementName][fieldItem.displayGroup].count++;
                                                        
                                                        //Update the List values of the display group, use the item properties to capture distinct values.
                                                        if (insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].count == 0) {
                                                            //Update the Item value section
                                                            if (insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].value != '') {
                                                                insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].value = insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].value + ', ';
                                                            }
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].value = insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].value + fieldValues.toString();
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].count++;

                                                            //Update the display group section
                                                            if (insertedLayerValues[featureElementName][fieldItem.displayGroup].list.value != '') {
                                                                insertedLayerValues[featureElementName][fieldItem.displayGroup].list.value = insertedLayerValues[featureElementName][fieldItem.displayGroup].list.value + ', ';
                                                            }
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].list.value = insertedLayerValues[featureElementName][fieldItem.displayGroup].list.value + fieldValues.toString();
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].list.count++;
                                                        }

                                                        //Update the summary values of the display group if the value is numeric
                                                        if (!isNaN(fieldValues)) {
                                                            //Update the Item summary section
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].summary.total.count++;
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].summary.total.value = insertedLayerValues[featureElementName][fieldItem.displayGroup].items[fieldValues].summary.total.value + Number(fieldValues);

                                                            //Update the display group section
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].summary.total.count++;
                                                            insertedLayerValues[featureElementName][fieldItem.displayGroup].summary.total.value = insertedLayerValues[featureElementName][fieldItem.displayGroup].summary.total.value + Number(fieldValues);
                                                        }

                                                    }
                                                }
                                            }

                                        }
                                    }
                                }
                                if (drillDownInfoLayer.displayWhen.toLowerCase() == 'always') {
                                    //if no features are returned, and display always is selected, add the content to the insertedLayerValues
                                    for (let fieldIndex = 0; fieldIndex < drillDownInfoLayer.displayFields.length; fieldIndex++) {

                                        let alwaysShowFieldItem = drillDownInfoLayer.displayFields[fieldIndex];
                                        if (alwaysShowFieldItem.alias && alwaysShowFieldItem.alias != "" && alwaysShowFieldItem.valueText) {

                                            //Replace field placeholders in Value Text elements with values from site properties.
                                            let properties = siteGeoJSON.features[0].properties;
                                            for (let propertyIndex = 0; propertyIndex < Object.keys(properties).length; propertyIndex++) {
                                                let key = Object.keys(properties)[propertyIndex];
                                                let re = new RegExp('{' + key + '}', 'gi');
                                                if (re.test(alwaysShowFieldItem.valueText)) {
                                                    alwaysShowFieldItem.valueText = alwaysShowFieldItem.valueText.replace(re, properties[key]);
                                                }
                                            }

                                            if (!insertedLayerValues[featureElementName]) {
                                                insertedLayerValues[featureElementName] = {};
                                            }
                                            if (!insertedLayerValues[featureElementName][alwaysShowFieldItem.alias]) {
                                                insertedLayerValues[featureElementName][alwaysShowFieldItem.alias] = {};
                                                insertedLayerValues[featureElementName][alwaysShowFieldItem.alias].layers = [];
                                                insertedLayerValues[featureElementName][alwaysShowFieldItem.alias].mapElementNames = [];
                                                insertedLayerValues[featureElementName][alwaysShowFieldItem.alias].items = {}
                                                insertedLayerValues[featureElementName][alwaysShowFieldItem.alias].items[alwaysShowFieldItem.valueText] = {};
                                            }

                                            if (insertedLayerValues[featureElementName][alwaysShowFieldItem.alias].layers.indexOf(drillDownInfoLayer) == -1) {
                                                insertedLayerValues[featureElementName][alwaysShowFieldItem.alias].layers.push(drillDownInfoLayer);
                                                insertedLayerValues[featureElementName][alwaysShowFieldItem.alias].mapElementNames.push(mapLayerPlaceholderElementName)
                                            }

                                            insertedLayerValues[featureElementName][alwaysShowFieldItem.alias].items[alwaysShowFieldItem.valueText].value = alwaysShowFieldItem.valueText.toString();
                                            insertedLayerValues[featureElementName][alwaysShowFieldItem.alias].items[alwaysShowFieldItem.valueText].count++;
                                        }


                                    }
                                }

                                for (let displayGroup in insertedLayerValues[featureElementName]) {
                                    let drillDownFeatureElementName = getHTMLCleanString(featureElementName + '-alias' + displayGroup);

                                    //Add the display group to the page if it does not already exist.
                                    let displayGroupText = displayGroup;
                                    if (drillDownInfoLayer.comments != null && drillDownInfoLayer.comments.length > 0) {
                                        displayGroupText = displayGroup + "<br>" + drillDownInfoLayer.comments;
                                    }

                                    if (drillDownInfoLayer.map && drillDownInfoLayer.map.comments != null && drillDownInfoLayer.map.comments.length > 0) {
                                        displayGroupText = displayGroupText + "<br>" + drillDownInfoLayer.map.comments;
                                    }

                                    if ($("#" + drillDownFeatureElementName).length == 0) {
                                        $("<tr><td>" + displayGroupText + "</td><td>" + getListTemplate(drillDownFeatureElementName, "none") + "</td></tr>").insertAfter("#" + layerPlaceholderElementName);
                                    }

                                    //Now show the map on the DOM, if showMap element set as true.
                                    for (var mapLayerIndex = 0; mapLayerIndex < insertedLayerValues[featureElementName][displayGroup].layers.length; mapLayerIndex++) {
                                        const displayGroupConfig = insertedLayerValues[featureElementName][displayGroup];
                                        if (displayGroupConfig.layers[mapLayerIndex] && displayGroupConfig.layers[mapLayerIndex].showMap == true) {
                                            showDrillDownMap(
                                                displayGroupConfig.mapElementNames[mapLayerIndex],
                                                displayGroupConfig.layers[mapLayerIndex],
                                                siteGeoJSON,
                                                displayGroupText);
                                        }
                                    }

                                    if (!insertedLayerValues[featureElementName][displayGroup].display) {
                                        insertedLayerValues[featureElementName][displayGroup].display = 'items';
                                    }

                                    switch (insertedLayerValues[featureElementName][displayGroup].display) {
                                        case 'allItems': { //items
                                            for (let item in insertedLayerValues[featureElementName][displayGroup].items) {
                                                if (!insertedLayerValues[featureElementName][displayGroup].items[item].displayed) {
                                                    $("#" + drillDownFeatureElementName).append("<li class='list-group-item li-report'>" + insertedLayerValues[featureElementName][displayGroup].items[item].value + "</li>");
                                                    insertedLayerValues[featureElementName][displayGroup].items[item].displayed = true;
                                                }
                                            }

                                            break;
                                        }
                                        default: { //items
                                            const expandID = drillDownFeatureElementName + '-expandItems';

                                            for (let item in insertedLayerValues[featureElementName][displayGroup].items) {
                                                if (!insertedLayerValues[featureElementName][displayGroup].items[item].displayed) {
                                                    let itemCount = $("ul#" + drillDownFeatureElementName + " li").length
                                                    if (itemCount == 8) {

                                                        var expandHTML = "<button type='button' class='btn btn-primary btn-xs ' id ='" + expandID + "-btn' data-toggle='collapse' data-target='#" + expandID + "'>" +
                                                            "<i class='bi bi-chevron-down'></i> More Results</button>";

                                                        $("#" + drillDownFeatureElementName).append(expandHTML);
                                                        $("#" + drillDownFeatureElementName).append(getDivTemplate(expandID, 'collapse'));

                                                        $("#" + expandID + "-btn").on('click', function () {
                                                            $(this).html($(this).html() == "<i class='bi bi-chevron-up'></i> Less Results" ? "<i class='bi bi-chevron-down'></i> More Results" : "<i class='bi bi-chevron-up'></i> Less Results");
                                                        });
                                                    }
                                                    if (itemCount >= 8) {
                                                        $("#" + expandID).append("<li class='list-group-item li-report'>" + insertedLayerValues[featureElementName][displayGroup].items[item].value + "</li>");

                                                    } else {
                                                        $("#" + drillDownFeatureElementName).append("<li class='list-group-item li-report'>" + insertedLayerValues[featureElementName][displayGroup].items[item].value + "</li>");

                                                    }
                                                    insertedLayerValues[featureElementName][displayGroup].items[item].displayed = true;
                                                }
                                            }

                                            break;
                                        }
                                        case 'marker': {
                                            let markerId = 1;
                                            for (var item in insertedLayerValues[featureElementName][displayGroup].items) {
                                                if (!insertedLayerValues[featureElementName][displayGroup].items[item].displayed) {
                                                    $("#" + drillDownFeatureElementName).append("<li class='list-group-item li-report'>" + insertedLayerValues[featureElementName][displayGroup].items[item].value + "</li>");
                                                    insertedLayerValues[featureElementName][displayGroup].items[item].displayed = true;
                                                    markerId = markerId + 1
                                                }
                                            }

                                            break;
                                        }
                                        case 'list': {
                                            $("#" + drillDownFeatureElementName).html("<li class='list-group-item li-report'><span class='badge'>Found " + insertedLayerValues[featureElementName][displayGroup].list.count + " items</span>" + insertedLayerValues[featureElementName][displayGroup].list.value + "</li>");
                                            break;
                                        }
                                        case 'count': {
                                            $("#" + drillDownFeatureElementName).html("<li class='list-group-item li-report'><span class='badge'>Found " + insertedLayerValues[featureElementName][displayGroup].list.count + " items</span>" + insertedLayerValues[featureElementName][displayGroup].list.count + " (Count)</li>");
                                            break;
                                        }
                                        case 'total': {
                                            $("#" + drillDownFeatureElementName).html("<li class='list-group-item li-report'><span class='badge'>Found " + insertedLayerValues[featureElementName][displayGroup].summary.total.count + " items</span>" + insertedLayerValues[featureElementName][displayGroup].summary.total.value + ' (' + insertedLayerValues[featureElementName][displayGroup].summary.total.type + ")</li>");
                                            break;
                                        }
                                    }
                                }
                            }

                        }


                    }
                    break; //end of Table Case statement;
                default:
                    $('#warning').html('<strong>Warning:</strong> ' + 'The report element type ' + drillDownInfoElement.type + ' is not supported, please correct the report configuration file.');
                    $('#warning').show();


            }
        }
    }
}

/**
 * Creates a deep merge of the objects
 * @param targetObject = the object that will get properties ammended to
 * @param sourceObject = the source of the properties and values
 * @param overwrite = forces overwriting of target object values, default is false
 * @returns {*}
 */
function mergeRecursiveObject(targetObject, sourceObject, overwrite) {
    overwrite = overwrite || false;
    for (var p in sourceObject) {
        //property in target object set; update its value
        if (sourceObject[p].constructor == Object) {
            if (!targetObject[p]) {
                targetObject[p] = {};
            }
            targetObject[p] = mergeRecursiveObject(targetObject[p], sourceObject[p], overwrite);
        } else if (targetObject[p] == null || overwrite) {
            targetObject[p] = sourceObject[p];
        }
    }
    return targetObject;
}

/**
 *
 * @param elementName - the name of an existing DOM element to replace with the map
 * @param layerElement - the layer JSON object that contains all the info to render the map
 */
function showDrillDownMap(sectionElementName, layerElement, siteGeoJSONMap, mapTitle) {
    //check to see if the map has already been created, if so, exit
    if ($("#" + getHTMLCleanString(sectionElementName + "-map")).length > 0) {
        return;
    }

    //Create the section using a template to add the map into.
    var sectionElementHTML = getSectionTemplate(sectionElementName);  //Get the default section template HTML snippet.
    $(sectionElementHTML).replaceAll("#" + sectionElementName); //Add the HTML snippet to the DOM.
    $("#" + sectionElementName + "-title").html('<p>' + mapTitle + '</p>'); //Add the title to the section

    const reportMap = new ReportMap();
    $(reportMap).appendTo("#" + sectionElementName + "-maps");
    let mapElementName = getHTMLCleanString(sectionElementName + "-map");
    reportMap.setup(siteGeoJSONMap, mapElementName, layerElement, true);
}

/**
 * Update the drill down information section in the report, generating from the drillDownInfo element report configurations.
 * @param siteGeoJSON - the result from applying the site filter using the identitySiteLocation function - in GeoJSON format.
 * @param reportConfig - created from evaluating the report configuration file.  Defaults to report if not specified.
 */
function showSiteInformation(siteGeoJSON, reportConfig, sectionName) {
    reportConfig = reportConfig || report;
    let reportConfigSection = reportConfig[sectionName];

    siteGeoJSON = appendLatLongProperties(siteGeoJSON)

    if (siteGeoJSON.features.length > 0 && reportConfigSection) {
        for (let drillDownInfoElementIndex = 0; drillDownInfoElementIndex < reportConfigSection.elements.length; drillDownInfoElementIndex++) {
            let drillDownInfoElement = reportConfigSection.elements[drillDownInfoElementIndex];
            switch (drillDownInfoElement.type) {
                case "table": {
                    //Create the section using a template to add the table into.
                    let sectionElementName = getHTMLCleanString(sectionName + "-element" + drillDownInfoElementIndex);
                    let sectionElementHTML = getSectionTemplate(sectionElementName);  //Get the default section template HTML snippet.
                    $(sectionElementHTML).appendTo("#siteInfoDiv"); //Add the HTML snippet to the DOM.
                    $("#" + sectionElementName + "-title").html(drillDownInfoElement.title); //Add the title to the section
                    $("#" + sectionElementName + "-desc").html(drillDownInfoElement.description); //Add the description to the section


                    //Update other DOM elements that contain siteCount tagged elements.
                    if (siteGeoJSON.features.length > 1) {
                        for (let multSiteCommentIndex = 0; multSiteCommentIndex < reportConfig.siteInfo.multiSiteComments.length; multSiteCommentIndex++) {
                            const multiSiteComment = reportConfig.siteInfo.multiSiteComments[multSiteCommentIndex];
                            $("#" + multiSiteComment.tagID).html(multiSiteComment.description);
                        }

                        $("[id^=sitecount]").text(siteGeoJSON.features.length);
                        $("[id^=additionalsitecount]").text(siteGeoJSON.features.length - 1);
                    }

                    //Create a new section table for each site feature
                    for (let siteFeatureIndex = 0; siteFeatureIndex < siteGeoJSON.features.length; siteFeatureIndex++) {

                        //Create the section using a template to add the table into.
                        let featureElementName = getHTMLCleanString(sectionElementName + "-feature" + siteFeatureIndex);
                        let featureElementHTML = getTableTemplate(featureElementName);  //Get the default section template HTML snippet.
                        $(featureElementHTML).appendTo("#" + sectionElementName + "-body"); //Add the HTML snippet to the DOM.
                        if (siteGeoJSON.features.length > 1) { //If only one feature, don't show the feature Title.
                            $("#" + featureElementName + "-head").append("<tr><td colspan='2'><b>" + reportConfig.drillDownInfo.multiSitePrefix + " " + (siteFeatureIndex + 1) + "</b><span id='multiFeatureDefaultValue-" + siteFeatureIndex + "'></span> </td></tr>");
                        }

                        for (let layerIndex = 0; layerIndex < drillDownInfoElement.layers.length; layerIndex++) {

                            //Add the placeholder for the layer to retain the ordering.
                            let layerPlaceholderElementName = getHTMLCleanString(featureElementName + '-layer' + layerIndex);
                            if ($("#" + layerPlaceholderElementName).length == 0) {
                                $("#" + featureElementName + "-body").append("<div id='" + layerPlaceholderElementName + "'></div>");
                            }

                            let drillDownInfoLayer = populateLayerDefaults(drillDownInfoElement, drillDownInfoElement.layers[layerIndex]);


                            let responseDrillDownGeoJSON = siteGeoJSON;
                            var responseFeatureElementName = featureElementName;
                            let responseDrillDownInfoLayer = drillDownInfoLayer;
                            var responseLayerPlaceholderElementName = layerPlaceholderElementName;

                            let properties = responseDrillDownGeoJSON.features[siteFeatureIndex].properties;

                            //get the display field item that matches the properties key.  This is to get the alias and display fields.
                            for (let displayFieldIndex = 0; displayFieldIndex < responseDrillDownInfoLayer.displayFields.length; displayFieldIndex++) {
                                let displayFieldItem = responseDrillDownInfoLayer.displayFields[displayFieldIndex];

                                let fieldItem = null;
                                for (let propertyIndex = 0; propertyIndex < Object.keys(properties).length; propertyIndex++) {
                                    let key = Object.keys(properties)[propertyIndex];

                                    if (displayFieldItem.valueText && propertyIndex == 0) { //only do for the first field, otherwise one for each is returned
                                        let displayFieldKeys = Object.keys(displayFieldItem)
                                        fieldItem = {};
                                        for (let displayFieldKeyIndex = 0; displayFieldKeyIndex < displayFieldKeys.length; displayFieldKeyIndex++) {
                                            fieldItem[displayFieldKeys[displayFieldKeyIndex]] = displayFieldItem[displayFieldKeys[displayFieldKeyIndex]];
                                        }
                                        break;
                                    } else if (displayFieldItem.fields != null) {
                                        let displayFieldNames = displayFieldItem.fields.toUpperCase().split(",");
                                        for (let displayFieldNameIndex = 0; displayFieldNameIndex < displayFieldNames.length; displayFieldNameIndex++) {
                                            if (displayFieldNames[displayFieldNameIndex].trim().toUpperCase() == (key.trim().toUpperCase())) {
                                                let displayFieldKeys = Object.keys(displayFieldItem)
                                                fieldItem = {};
                                                for (let displayFieldKeyIndex = 0; displayFieldKeyIndex < displayFieldKeys.length; displayFieldKeyIndex++) {
                                                    fieldItem[displayFieldKeys[displayFieldKeyIndex]] = displayFieldItem[displayFieldKeys[displayFieldKeyIndex]];
                                                }
                                                break;
                                            }
                                        }
                                    }
                                }

                                //Display the fieldItem values and alias in the report.
                                if (report.status == "development" || fieldItem) {

                                    if (fieldItem == null) {
                                        //if the fielditem is null, ie for development purposes, show all fields.
                                        fieldItem = { displayGroup: "(Note: Shown for Development Purposes Only)", fields: '' };
                                    }


                                    var fieldValues = null;
                                    if (fieldItem.fields != null) {
                                        //Concat the field values into a single string for multi field name fielditems.
                                        var fieldItemFieldNames = fieldItem.fields.split(",");
                                        for (var fieldValueIndex = 0; fieldValueIndex < fieldItemFieldNames.length; fieldValueIndex++) {
                                            if (properties.hasOwnProperty(fieldItemFieldNames[fieldValueIndex])) {
                                                if (fieldValues != null) {
                                                    fieldValues = fieldValues + ' ' + properties[fieldItemFieldNames[fieldValueIndex]];
                                                } else {
                                                    fieldValues = properties[fieldItemFieldNames[fieldValueIndex]];
                                                }
                                            }
                                        }
                                    }

                                    //Replace field placeholders in Alias & Value Text elements with actual field value.
                                    for (var propertyItemKey in properties) {
                                        let propertyValue = properties[propertyItemKey]
                                        if (propertyValue && propertyItemKey.toLowerCase().indexOf('date') != -1 && !isNaN(propertyValue)) {
                                            propertyValue = new Date(propertyValue).toDateString();
                                        }
                                        var re = new RegExp('{' + propertyItemKey + '}', 'gi')
                                        if (fieldItem.valueText != null && fieldItem.valueText != "" && re.test(fieldItem.valueText)) {
                                            fieldItem.valueText = fieldItem.valueText.replace(re, propertyValue);
                                        }
                                        if (fieldItem.displayGroup && re.test(fieldItem.displayGroup)) {
                                            fieldItem.displayGroup = fieldItem.displayGroup.replace(re, propertyValue);
                                        }
                                    }

                                    if (fieldItem.valueText != null && fieldValues != null) {
                                        fieldValues = fieldItem.valueText.replace('{fieldValue}', fieldValues);
                                    }


                                    //Write the field alias and values to the DOM table.
                                    if (fieldItem.displayGroup == null || fieldItem.displayGroup == "") {
                                        if (fieldItem.alias != null && fieldItem.alias != "") {
                                            fieldItem.displayGroup = fieldItem.alias;
                                        } else if (fieldItem.aliasField != null && fieldItem.aliasField != "") {
                                            fieldItem.displayGroup = properties[fieldItem.aliasField];
                                        } else {
                                            fieldItem.displayGroup = '';
                                        }
                                    }
                                    //console.log("Next Section - FieldItem: " + fieldItem.valueText)

                                    //Add the comment html snippet to the display group if defined.
                                    if (responseDrillDownInfoLayer.comments != null && responseDrillDownInfoLayer.comments.length > 0) {
                                        fieldItem.displayGroup = fieldItem.displayGroup + ":<br>" + responseDrillDownInfoLayer.comments;
                                    }

                                    //add the row title when processing the first returned record.
                                    var drillDownFeatureElementName = getHTMLCleanString(responseFeatureElementName + '-alias' + fieldItem.displayGroup);
                                    if ((fieldValues != null && responseDrillDownInfoLayer.displayWhen == null) ||
                                        (fieldValues != null && responseDrillDownInfoLayer.displayWhen == 'result') ||
                                        (fieldValues == null && responseDrillDownInfoLayer.displayWhen == 'no result') ||
                                        (responseDrillDownInfoLayer.displayWhen == 'always')
                                    ) {
                                        if (insertedDisplayGroups.indexOf(drillDownFeatureElementName) < 0) { // ($("#" + drillDownFeatureElementName).length == 0) {
                                            //replace the feidl alias after the layer placeholder to the feature element name
                                            //console.log("element name: " + drillDownFeatureElementName + ", Added display Group: " + fieldItem.displayGroup + ". Field Value length: " + fieldValues + " (" + fieldValues.trim().length + ")")
                                            $("<tr><td>" + fieldItem.displayGroup + "</td><td>" + getTableTemplate(drillDownFeatureElementName, "none") + "</td></tr>").insertAfter("#" + responseLayerPlaceholderElementName);
                                            insertedDisplayGroups.push(drillDownFeatureElementName);
                                            insertedLayerValues[drillDownFeatureElementName] = {};
                                        }
                                        var fieldValueElementName = getHTMLCleanString(drillDownFeatureElementName + "-" + fieldValues);
                                        //console.log ("field value element name : " + fieldValueElementName);
                                        if (!insertedLayerValues[drillDownFeatureElementName].hasOwnProperty(fieldValues)) {
                                            //This is the first record of this value for the display group
                                            $("#" + drillDownFeatureElementName + " tbody").prepend("<tr ><td id='" + fieldValueElementName + "'>" + fieldValues + "</td></tr>");
                                            insertedLayerValues[drillDownFeatureElementName][fieldValues] = {};
                                            //var insertedDisplayFieldValueIndex = insertedLayerValues[drillDownFeatureElementName].indexOf(fieldValues)
                                            insertedLayerValues[drillDownFeatureElementName][fieldValues].count = 1;
                                            insertedLayerValues[drillDownFeatureElementName][fieldValues].value = fieldValues;
                                            insertedLayerValues[drillDownFeatureElementName][fieldValues].summary = "";
                                        } else if (fieldItem.multiFeatureDisplay == 'true') {
                                            //This value aready exists in the display group, so show record Count
                                            var existingDisplayFieldValue = insertedLayerValues[drillDownFeatureElementName][fieldValues];
                                            if (isNaN(existingDisplayFieldValue) || isNaN(fieldValues)) {
                                                //Display the number of items found for non-numeric values.
                                                existingDisplayFieldValue.count = existingDisplayFieldValue.count + 1;
                                                existingDisplayFieldValue.summary = " (Found " + existingDisplayFieldValue.count + " items).";
                                            } else {
                                                //Calculate the sum of the field values for numeric fields
                                                existingDisplayFieldValue.value = existingDisplayFieldValue + fieldValues;
                                                existingDisplayFieldValue.summary = " (Total value for " + existingDisplayFieldValue.count + " items).";
                                            }
                                            $("#" + fieldValueElementName).html(existingDisplayFieldValue.value + existingDisplayFieldValue.summary);

                                        }

                                        //Update other DOM elements that contain field tagged elements.
                                        if (fieldItem.multiFeatureDisplay == "true") {
                                            $("#multiFeatureDefaultValue-" + siteFeatureIndex).append(", " + fieldValues);
                                        }
                                        $("[id^='" + fieldItem.displayGroup + "']").text(fieldValues);

                                    }
                                }
                            }
                        }

                        if (getUrlParam('geojson')) {
                            $("#" + featureElementName + "-body").append("<tr><td>Site Location</td><td>User specified location.</td></tr>");
                        }

                        if (getUrlParam('distance')) {
                            $("#" + featureElementName + "-body").append("<tr><td>Search distance around site.</td><td>" + getUrlParam('distance') + "m</td></tr>");
                        }

                    }
                    break; //end of Table Case statement;
                }
                case "map": {
                    showMap(siteGeoJSON, sectionName, drillDownInfoElement, drillDownInfoElementIndex);
                    break;
                }
                default: {
                    $('#warning').html('<strong>Warning:</strong> ' + 'The report element type ' + drillDownInfoElement.type + ' is not supported, please correct the report configuration file.');
                    $('#warning').show();
                }
            }
        }
    }
}

function showMap(siteGeoJSON, sectionName, mapConfig, drillDownInfoElementIndex) {
    //Create the section using a template to add the map into.
    let sectionElementName = getHTMLCleanString(sectionName + "-element" + drillDownInfoElementIndex);
    let sectionElementHTML = getSectionTemplate(sectionElementName);  //Get the default section template HTML snippet.
    $(sectionElementHTML).appendTo("#siteInfoDiv"); //Add the HTML snippet to the DOM.
    $("#" + sectionElementName + "-title").html(mapConfig.title); //Add the title to the section
    $("#" + sectionElementName + "-desc").html(mapConfig.description); //Add the description to the section

    //const reportMap = new ReportMap();
    const reportMap = document.createElement("report-map");
    $(reportMap).appendTo("#" + sectionElementName + "-maps");
    let mapElementName = getHTMLCleanString(sectionElementName + "-element-Map" + drillDownInfoElementIndex);
    reportMap.setup(siteGeoJSON, mapElementName, mapConfig, false);
}

function showFloodInformation(siteGeoJSON, reportConfig, sectionName) {
    //Create the section using a template to add into.
    let sectionElementName = getHTMLCleanString(sectionName);
    let sectionElementHTML =
        "<div class='panel panel-default' id='" + sectionElementName + "'>" +
        "<div class='panel-body' id='" + sectionElementName + "-body'></div>" +
        "</div>";
    $(sectionElementHTML).appendTo("#floodInfoDiv");
    const floodInfo = new FloodInfo();
    const floodInfoParentElementName = sectionElementName + "-body";
    $(floodInfo).appendTo("#" + floodInfoParentElementName);
    floodInfo.setup(siteGeoJSON, reportConfig, floodInfoParentElementName);
}

function getIconButton(id, className, title) {
    if (!title) { title = ''; }

    var htmlString =
        htmlString =
        '<button class="btn" id="' + id + '" type="button" title="' + title + '">' +
        '<i class="' + className + '"></i>' +
        '   </button>';

    return htmlString;
}

function appendTableRowWarningCouldNotLoadAfterElement(elementId, problemLayerName) {
    $("<tr class=\"alert alert-danger\" style=\"border-color: transparent\"><td>Could not load " + (problemLayerName || 'a resource') + "</td><td></td></tr>").insertAfter("#" + elementId);
}

function getUrlParam(name) {
    let url = new URL(window.location.href);
    let param = url.searchParams.get(name);
    return param;
}